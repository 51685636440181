<template>
    <div class="game-com-game-config-win flex-center">
        <confirm-pro ref="confirmWin" v-if="styleShare">
            <div class="win-body-confirm">
                <div class="body-remind-time flex">
                    <span class="text-large-a t bold">倒计时</span>
                    <span class="text-large-a c bold">{{remainTimeText}}</span>
                </div>
                <div class="body-room-num flex">
                    <div class="num-item a">
                        <span class="text-normal-ab t bold">房间号:</span>
                        <span class="text-normal-ab c bold">{{roomNum}}</span>
                    </div>
                    <div class="num-item b scale-button" @click="copyRoomNum">
                        <div class="b-body flex-center">
                            <span class="text-mini-a bold">复制</span>
                        </div>
                    </div>
                </div>
                <div class="body-item-type">
                    <div class="game-type flex">
                        <span class="text-mini-a bold t">下棋类型</span>
                        <span class="text-mini-a bold c ">{{gameTitle}}({{gameRule}})</span>
                    </div>
                    <div class="game-time">
                        <span class="text-mini-a bold t">每方用时</span>
                        <span class="text-mini-a bold c">{{gameTimeText}}</span>
                    </div>
                </div>
                <div class="body-item-ready position-align-center-margin scale-button" @click="gameroomReady" :class="{'disabled-img': loading}">
                    <div class="body flex-center">
                        <button-loading v-if="loading"></button-loading>
                        <!-- <span class="text-normal-a bold">{{isReady ? '已准备': '准备'}}</span> -->
                        <span class="text-normal-a bold">{{readyStateText}}</span>
                    </div>
                </div>
            </div>
            <div class="win-close scale-button cursor-pointer" slot="close" @click="closeRoom"></div>
        </confirm-pro>

        <confirm-pro ref="confirmWin" v-else>
            <div class="win-body-confirm user">
                <div class="body-item-user left">
                    <div class="body-remind-time flex">
                        <span class="text-large-a t bold">倒计时</span>
                        <span class="text-large-a c bold">{{remainTimeText}}</span>
                    </div>  

                    <div class="body-item-user-info">
                        <div class="info-item b">
                            <div class="info-photo">
                                <img :src="blackUserInfo.avatarHeadUrl" alt="">
                            </div>
                            <div class="info-text">
                                <div class="text-item a">
                                    <nickname class="bold" :nickname="blackUserInfo.nickname"></nickname>
                                </div>
                                <div class="text-item b">
                                    <user-level class="flex-center" :level="blackUserInfo.level"></user-level>
                                </div>
                                <div class="text-item c">
                                    <goal-difference :value="blackUserInfo.goalDifference"></goal-difference>
                                </div>
                            </div>
                        </div>
                        <div class="info-item w">
                            <div class="info-photo">
                                <img :src="whiteUserInfo.avatarHeadUrl" alt="">
                            </div>
                            <div class="info-text">
                                <div class="text-item a">
                                    <nickname class="bold" :nickname="whiteUserInfo.nickname"></nickname>
                                </div>
                                <div class="text-item b">
                                    <user-level class="flex-center" :level="whiteUserInfo.level"></user-level>
                                </div>
                                <div class="text-item c">
                                    <goal-difference :value="whiteUserInfo.goalDifference"></goal-difference>
                                </div>
                            </div>
                        </div>
                        <div class="info-item label">
                            <span class="text-mini-b bold">好友已就位</span>
                        </div>
                    </div>
                    <!-- :class="{'disabled-img': isReady, 'scale-button': !isReady}" -->
                    <div class="body-item-ready position-align-center-margin scale-button" @click="gameroomReady" :class="{'disabled-img': loading}">
                        <div class="body flex-center">
                            <button-loading v-if="loading"></button-loading>
                            <!-- <span class="text-normal-a bold">{{isReady ? '取消' : '开始对局'}}</span> -->
                            <span class="text-normal-a bold">{{readyStateText}}</span>
                        </div>
                    </div>
                </div>
                <div class="body-item-user right">
                    <div class="body-remind-time flex">
                        <span class="text-large-a t bold">约战信息</span>
                    </div>  
                    <div class="body-item-extend type">
                        <div class="type-text a">
                            <span class="text-mini-a bold flex-just-center">下棋类型</span>
                        </div>
                        <div class="type-text b">
                            <span class="text-normal-a bold flex-just-center">{{gameTitle}}</span>
                        </div>
                    </div>

                    <div class="body-item-extend time">
                        <div class="type-text a">
                            <span class="text-mini-a bold flex-just-center">每方用时</span>
                        </div>
                        <div class="type-text b">
                            <span class="text-normal-a bold flex-just-center">{{gameTimeText}}</span>
                        </div>
                    </div>

                    <div class="body-item-extend room-num">
                        <span class="text-normal-ab bold">房间号:</span>
                        <span class="text-normal-ab bold">{{roomNum}}</span>
                    </div>

                </div>
                <div class="win-close scale-button cursor-pointer" slot="close" @click="closeRoom"></div>
            </div>
        </confirm-pro>




        <!-- <div class="win-body">
            <div class="config-item count-down text-center">
                <span class="text-large">倒计时</span>
                <span class="text-large">{{remainTimeText}}</span>
            </div>
            <div class="config-item title">
                <div class="title-item">
                    <span class="text-large">房间号：</span>
                    <span class="text-large" ref="roomNum">{{roomNum}}</span>
                </div>
                <div class="title-item scale-button" @click="copyRoomNum">
                    <span class="text-large">复制</span>
                </div>
            </div>
            <div class="config-item content">
                <div class="content-item game-title">
                    <span class="text-normal">下棋类型：</span>
                    <span class="text-normal">{{gameTitle}}</span>
                </div>
                <div class="content-item game-rule">
                    <span class="text-normal">下棋规则：</span>
                    <span class="text-normal">{{gameRule}}</span>
                </div>
            </div>
            <div class="config-item foot">
                <div class="foot-item scale-button" @click="gameroomReady">
                    <span class="text-large">{{isReady ? '已准备': '准备'}}</span>
                </div>
                <div class="foot-item scale-button" @click="closeRoom" v-if="owner">
                    <span class="text-large">解散</span>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
    import apiClass from '@/API/page/socialGame'

    export default {
        name: '',
        props: {
            info: {
                type: Object,
                default(){ return {} } 
            },
            owner: Boolean,
            isReady: Boolean,
            userList: Array,
            loading: Boolean,
            inviterUserCode: [String, Number],
            inviteeUserCode: [String, Number],
            usercode: [String, Number],
        },
        data() {
            return {
                api: new apiClass(),
                remainTime: 0,
                countdownInterval: null,
                state: false
            };
        },
        methods: {
            async copyRoomNum(){
                let {roomNum} = this
                // let state = this._common.writeToClipBoard(roomNum)
                // let state = this._common.copyTextForMobile(roomNum)
                let state = await this.$copyText(roomNum)
                let message = state ? '复制成功' : '复制失败'
                this.$toast({
                    message
                })
            },
            async closeRoom(){
                // let state = await this.closeConfirm()
                // if(!state) return
                // let {gameroomId} = this
                // let res = await this.api.closeGameroom({gameroomId})
                this.$emit('close')
            },
            open(){
                if(!this.$refs.confirmWin) return
                let confirmType = this.styleShare ? 'c19' : 'c20'
                this.$refs.confirmWin.open({
                    confirmType,
                })
                this.state = true
            },
            close(){
                if(!this.$refs.confirmWin) return
                this.$refs.confirmWin.close()
            },
            async closeConfirm(){
                let message = this._errorCode.game['close room confirm']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '确定', type: 'confirm', label: 0},
                        {title: '取消', type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            countdownStart(){
                this.remainTime = this.gameroomRemainTime
                if(this.remainTime <= 0) return
                this.countdownAction()
            },
            countdownAction(){
                this.countdownStop()
                let t = 1000
                this.countdownInterval = setInterval(() => {
                    if(this.remainTime <= 0){
                        this.$emit('timesup')
                        return this.countdownStop()
                    }
                    this.remainTime -= t
                }, t)
            },
            countdownStop(){
                if(!this.countdownInterval) return
                clearInterval(this.countdownInterval)
            },
            gameroomReady(){
                this.$emit('gameroomReady')
            },
        },
        created() {},
        components: {},
        computed: {
            styleShare(){
                return this.userList.length <= 1 && this.owner
            },
            readyStateText(){
                if(this.isReady) return '取消准备'
                let {inviterUserCode, inviteeUserCode} = this
                if(inviterUserCode || inviteeUserCode) return '开始对局'
                return '准备'
            },
            blackUserInfo(){
                let {userList} = this
                userList = userList || []
                return userList[0] || {}
            },
            whiteUserInfo(){
                let {userList} = this
                userList = userList || []
                return userList[1] || {}
            },
            gameTimeText(){
                let {mainTime, countdownTime, countdownNum} = this.info || {}
                mainTime = mainTime / 1000
                countdownTime = countdownTime / 1000
                let min = parseFloat((mainTime / 60).toFixed(1))
                let sec = mainTime % 60
                let text = `${min}分钟`
                if(countdownTime > 0 && countdownNum > 0) text += `${countdownTime}秒${countdownNum}次`
                return text
            },
            gameroomId(){
                let {gameroomId} = this.info || {}
                return gameroomId
            },
            roomNum(){
                let {roomNum} = this.info || {}
                return roomNum
            },
            gameTitle(){
                let {label} = this.info || {}
                return label
            },
            gameRule(){
                let {intro} = this.info || {}
                return intro
            },
            gameroomRemainTime(){
                let {currentRemainTime} = this.info || {}
                return currentRemainTime || 0
            },
            remainTimeText(){
                let {remainTime: time} = this
                if(time < 0) time = 0
                let s = parseInt(time / 1000)
                let m = parseInt(s / 60)
                s = s % 60
                if(m < 10) m = `0${m}`
                if(s < 10) s = `0${s}`
                return `${m}:${s}`
            }
        },
        watch: {
            gameroomRemainTime: {
                handler(){
                    this.countdownStart()
                },
                deep: true,
                immediate: true
            },
            styleShare: {
                async handler(val, oldVal){
                    if(val === oldVal) return
                    this.close()
                    await this._common.nextTick.call(this)
                    this.open()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.open()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.countdownStop()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .game-com-game-config-win{
        // z-index: 301;
        // position: absolute;
        // top: 0px;
        // left: 0px;
        // width: 100%;
        // height: 100%;
        // color: #77554C;


        


        .win-body{
            position: relative;
            padding: 10px;
            width: 300px;
            height: 200px;
            background: #FEF9EB;
            border-radius: 15px;
            .config-item+ .config-item{
                margin-top: 10px;
            }
            .config-item.title{
                display: flex;
                justify-content: space-between;
            }
            .config-item.foot{
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                padding: 10px 30px;
                display: flex;
                justify-content: space-between;
            }
        }

        

        .win-body-confirm{
            padding: 44px 0px 14px 0px;
            .body-remind-time{
                white-space: nowrap;
                color: #fff;
                width: 160px;
                height: 32px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/preview_window/p1.png'));
                margin: 0px auto;
                align-items: center;
                padding: 0px 13px;
                justify-content: space-between;
            }

            .body-room-num{
                position: relative;
                width: 100%;
                height: 35px;
                margin-top: 5px;
                padding: 0px 33px 0px 45px;
                .num-item.a{
                    width: 120px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #FF728A;
                }
                .num-item.b{
                    width: 53px;
                    height: 35px;
                    position: absolute;
                    left: 205px;
                    top: 0px;
                    padding: 6px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/preview_window/p2_shadow.png'));
                    .b-body{
                        color: #978179;
                        width: 41px;
                        height: 23px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/preview_window/p2.png'));
                    }
                }
            }
            .body-item-type{
                margin-top: 24px;
                padding-left: 36px;
                padding-right: 33px;
                color: #978179;
                .game-type{
                    .c{
                        margin-left: 13px;
                    }
                }
                .game-time{
                    margin-top: 26px;
                    .c{
                        margin-left: 16px;
                    }
                }
            }
            .body-item-ready{
                width: 142px;
                height: 50px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/preview_window/p3_shadow.png'));
                bottom: 14px;
                padding: 5px;
                .body{
                    color: #B07213;
                    width: 100%;
                    height: 100%;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/preview_window/p3.png'));
                }
            }

            .body-item-user-info{
                position: relative;
                margin: 0px auto;
                margin-top: 19px;
                width: 236px;
                height: 127px;
                display: flex;
                justify-content: space-between;
                .info-item{
                    padding: 8px;
                    height: 100%;
                    width: 88px;
                    .info-photo{
                        width: 73px;
                        height: 73px;
                        background: #fff;
                        img{
                            width: 100%;
                            height: auto;
                            float: left;
                        }
                    }
                    .info-text{
                        margin-top: 5px;
                        .global-com-user-level{
                            justify-content: flex-start;
                        }
                        span[class^="text"]{
                            font-size: 8px;
                            display: flex;
                            justify-content: center;
                            font-weight: bold;
                        }
                        .com-global-goal-difference{
                            .diff-item{
                                width: 10px;
                                height: 10px;
                            }
                        }
                        .global-com-user-level{
                            color: #C1465F;
                        }
                        .text-item.b{
                            margin: 3px 0px;
                        }   


                        
                    }
                }
                .info-item.w{
                    .text-item.b{
                        .global-com-user-level{
                            color: #5B5EB2;
                        }
                    }
                }
                .info-item.label{
                    padding: 0px;
                    position: absolute;
                    left: 88px;
                    width: calc(100% - 88px * 2);
                    top: 72px;
                    span{
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
        .win-close{
            position: absolute;
            top: 8px;
            right: -2px;
            width: 18px;
            height: 19px;
            z-index: 2;
            .background-img-max(url('@{assetsUrlV2_0}/global/c1_3.png'));
        }
        .win-body-confirm.user{
            padding: 0px;
            padding-top: 43px;
            position: relative;
            .body-item-user{
                position: absolute;
                top: 0px;
                padding-top: 43px;
            }
            .body-item-user.left{
                width: 291px;
                height: 286px;
                left: 0px;
                .body-remind-time{
                    height: 20px;
                    white-space: nowrap;
                    width: 133px;
                    height: auto;
                    margin: 0px;
                    margin-left: 77px;
                    background: none;
                    padding: 0px;
                }
            }
            .body-item-user.right{
                width: 197px;
                height: 286px;
                right: 0px;
                .body-remind-time{
                    height: 20px;
                    color: #FF728A;
                    background: none;
                    margin: 0px;
                    padding: 0px;
                    width: 100%;
                    justify-content: center;
                }
                .body-item-extend{
                    margin: 0px auto;
                    margin-top: 34px;
                    width: 122px;
                    text-align: center;
                    .a{
                        color: #978179;
                    }
                    .b{
                        margin-top: 10px;
                        color: #A1A4FF;
                    }
                }
                .body-item-extend.time{
                    margin-top: 29px;
                }
                .body-item-extend.room-num{
                    margin-top: 26px;
                    width: 120px;
                    color: #978179;
                    display: flex;
                    justify-content: space-between;
                }
            }
            
        }
        
    }
</style>