<template>
    <div class="page-com-game-room-index game-action-style">
        <component-bcg url="exercise/bcg.png"></component-bcg>
        <div class="ex-bcg-box">
            <div class="stone-item black"></div>
            <div class="stone-item white"></div>
        </div>
        <div class="game-index-common-box" :class="{'visibility-hidden': isCreatedStatus}">
            <!-- <div class="game-result-box flex-center" v-if="gameResult">
                <result class="text-normal text-yellow-color i" :result="gameResult"></result>
            </div> -->
            <div class="capture-body">
                <div class="cap-item board game-board-box" ref="boardBox">
                    <div class="board-body">
                        <board ref="board" :boardSize.sync="boardSize" @emitEvent="emitEvent" :minNum.sync="boardMinNum"></board>
                    </div>
                </div>
                <div class="cap-item options game-options-box">
                    <div class="options-body">
                        <div class="opt-item title text-center">
                            <span class="text-normal-ab bold">{{optTitle}}</span>
                        </div>
                        <div class="opt-item rule text-center flex-center">
                            <span class="text-mini-a light-text-color flex-center bold">{{gameRule}}</span>
                        </div>
                        <div class="opt-item remove-box flex-center">
                            <div class="re-item flex-center">
                                <span class="text-normal bold">{{blackRemove}}</span>
                            </div>
                            <div class="re-item title flex-center">
                                <span class="text-mini-a bold">{{removeTitle}}</span>
                            </div>
                            <div class="re-item flex-center">
                                <span class="text-normal bold">{{whiteRemove}}</span>
                            </div>
                        </div>
                        <div class="opt-item g-info">
                            <div class="info-item content">
                                <div class="con-item left flex-just-center">
                                    <div class="left-bcg bcg-item"></div>
                                    <div class="stone black" :class="{'active': !gameResult && colorNow == 1}"></div>
                                    <avatarPet type="concise" pageName="user" :scaleNum="2" :gif="false" :petTake="false" :userInfo.sync="blackUserInfo" v-if="blackUserInfo.usercode && pageHasInit"></avatarPet>
                                    <div class="name-board flex-center">
                                        <span class="text-mini-c bold">{{blackUserInfo.nickname}}</span>
                                    </div>
                                </div>
                                <div class="con-item right flex-just-center">
                                    <div class="right-bcg bcg-item"></div>
                                    <div class="stone white" :class="{'active': !gameResult && colorNow == -1}"></div>
                                    <avatarPet type="concise" pageName="user" :scaleNum="2" :gif="false" :petTake="false" :userInfo.sync="whiteUserInfo" v-if="whiteUserInfo.usercode && pageHasInit"></avatarPet>
                                    <div class="name-board flex-center">
                                        <span class="text-mini-c bold">{{whiteUserInfo.nickname}}</span>
                                    </div>
                                </div>
                                <div class="con-item label-box">
                                    <div class="body"></div>
                                </div>
                            </div>
                        </div>
                        <div class="opt-item time-box">
                            <div class="time-box-body" v-if="!countdownDisabled">
                                <div class="time-item a">
                                    <gameTime ref="blackTime" 
                                    color="black"
                                    :mainTime.sync="mainTime" 
                                    :countdownTime.sync="countdownTime" 
                                    :countdownNum.sync="countdownNum" 
                                    :timeoutStepCallback="blackTimeoutSetp"
                                    type="ms"
                                    @change="timeChange('black', $event)"
                                    @timeout="timeout('black')"></gameTime>
                                </div>
                                <div class="time-item b">
                                    <gameTime ref="whiteTime" 
                                    color="white"
                                    :mainTime.sync="mainTime" 
                                    :countdownTime.sync="countdownTime" 
                                    :countdownNum.sync="countdownNum" 
                                    :timeoutStepCallback="whiteTimeoutSetp"
                                    type="ms"
                                    @change="timeChange('white', $event)"
                                    @timeout="timeout('white')"></gameTime>
                                </div>
                            </div>
                            
                        </div>
                    <div class="opt-item game-fun">
                        <div class="game-fun-body">
                            <div class="fun-item l">
                                <stoneLabelSwitch :value="stoneLabelValue" @change="changeLabel"></stoneLabelSwitch>
                            </div>
                            <div class="fun-item normal c flex-center"
                            @click="funHand(v)" v-for="(v, i) in funListCul" :key="i"
                            :class="[v.class, {'capture': isCapture, 'disabled-img': v.isDisabled, 'scale-button': !v.isDisabled}]"
                            >
                                <div class="normal-btn-icon" :class="v.icon"></div>
                                <div class="normal-btn-text">
                                    <span class="text-mini-a bold flex-center">{{v.name}}</span>
                                </div>
                            </div>
                        </div>
                            
                        </div>
                        <div class="opt-item game-fun-foot flex-center max-width">
                            <!--  -->
                            <confirmPlay @click="confirmPlay" :disabled="confirmDisabled" v-if="confirmPlaySwitch">
                                <span class="text-normal-a">{{pageText.t6}}</span>
                            </confirmPlay>
                            <div class="setting-box scale-button" @click="openSetting">
                                <span class="text-mini-a text bold">设置</span>
                            </div>
                        </div>
                    </div>
                    <optionsMask ref="optionsMask" v-if="judgeMaskDisplay">
                        <div class="game-judge-result max-size flex-center">
                            <div class="re-body relative">
                                <div class="re-b-item a">
                                    <stone :color="winColor" v-if="winColor" shadow="light"></stone>
                                    <div class="result-shadow" v-if="winColor">
                                        <div class="shadow-two">
                                            <result class="re-b-text b" :result="gameResult"></result>
                                        </div>
                                    </div>
                                    <div class="re-b-text text-normal-aa text-center bold flex-center" v-else>{{pageText['t20']}}</div>
                                </div>
                                <!-- <div class="game-play-gift flex-center" v-if="gift">
                                    <div class="gift-body">
                                        <div class="icon"></div>
                                        <div class="text">
                                            <span class="text-normal-d bold">+{{gift}}</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="re-b-item b">
                                    <div class="b-item">
                                        <confirmPlay type="normal" @click="quit" :showShadow="false">
                                            <span class="text-normal-ab">{{continueText}}</span>
                                        </confirmPlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </optionsMask>
                </div>
            </div>
            <actionConfirm ref="actionConfirm" ></actionConfirm>
        </div>
        <div class="game-index-common-box config" v-if="isCreatedStatus" >
            <div class="page-title-config">
                <span class="text-large-c bold">好友约战</span>
            </div>
            <preGameWindow ref="preGameWindow" 
            :info.sync="preGameInfo" 
            @close="closeRoom" 
            @timesup="gameroomInfoInit()" 
            v-if="isCreatedStatus && gameroomInfo" 
            :owner="roomOwner" 
            :isReady.sync="isReady"
            :userList="configUserInfoList"
            :loading="readyLoading"
            :inviterUserCode="inviterUserCode"
            :inviteeUserCode="inviteeUserCode"
            :usercode="usercode"
            @gameroomReady="gameroomReady"></preGameWindow>
        </div>
        <gameResult @emitEvent='emitEvent' ref="gameResultWindow"
        :usercode="usercode"
        :gameType="gameMetaType"
        :result="gameResult"
        :oldLevel="userLevel"
        :gameMeta.sync="sgfMeta"
        :userColor="userColor" 
        :invalidGame="invalidGame" 
        :loading="resultFunLoading"
        :gameId.sync="gameId"
        :isTaste.sync="isTaste"
        :gift.sync="gift"
        ></gameResult>
        <gameColorNoticePro ref="gameColorNoticePro" :color="userColor == 1 ? 'black' : 'white'" :appStyle.sync="appStyle"></gameColorNoticePro>
        <setting ref="setting" @change="settingChange"></setting>
        <loading ref="gameLoading" class="game-loading"></loading>
    </div>
</template>
<script>
    import board from '@/components/board.vue'
    import socketHand from '@/plugins/websocket/socketHand'
    import apiClass from '@/API/page/socialGame'
    import gameApiClass from '@/API/page/game'
    import preGameWindow from '../components/gameConfigWin.vue'
    import actionConfirm from '../components/actionConfirm.vue'
    import gameTime from '@/components/game/gameTime.vue'
    import playConfig from './play'
    import gameResult from '../components/gameResult.vue'
    import gameColorNoticePro from '../../../components/gameColorNoticePro.vue'
    import setting from '../components/setting.vue'
    import optionsMask from '@/components/optionsMask.vue'
    import gameJudgeConfig from '@/plugins/tool/gameJudge'
    import gameReviewConfig from './gameReview'
    import loading from '@/components/loading.vue'


    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                ...playConfig.data.call(this),
                ...gameJudgeConfig.data.call(this),
                ...gameReviewConfig.data.call(this),
                funList: [
                    {icon: 'judge', fun: 'gameJudgeCon', class: 'judge', name: '数子', display: 'gameJudgeDisplay'},
                    {icon: 'pass', fun: 'passControl', class: 'btm pass', name: '停一手', display: 'passDisplay', disabledCul: 'passDisabled'},
                    {icon: 'resign', fun: 'resign', class: 'btm resign', name: '认输', display: 'resignDisplay'},
                    {icon: '', fun: 'undoControl', class: 'btm pass', name: '申请悔棋', display: 'undoDisplay', disabledCul: 'undoDisabled'},
                    {icon: '', fun: 'setResult_draw', class: 'btm pass', name: '申请和棋', display: 'drawDisplay', disabledCul: 'drawDisabled'},
                ],
                appData: new this.appDataClass({key: 'game_play_data'}),
                api: new apiClass(),
                gameApi: new gameApiClass(),
                socketHand: new socketHand(),
                stompState: false,
                pageDestroy: false,
                gameroomId: this.$route.params.gameroomId,
                pathNow: this.$route.path,
                // playerList: [],
                gameroomInfo: null,
                gameroomInfoInterval: null,
                roomInfoSplitTime: 10,
                userInfoSplitTime: 30,
                roomInfoLastTime: 0,
                userListInterval: null,
                disconnectUser: {},
                pageHasInit: false,
                gameSgfInfo: {},
                boardState: false,
                gameList: [],
                roomUserList: [],
                userListPage: 0,
                userListSize: 10,
                userInfo: {},
                removeStone: null,
                otherUserInfo: {},
                quitSetAction:false,
                gameSubscribeState: false,
                now: 1,
                position: 0,
                step: 0,
                timeHasInited: false,
                pageText: this.TEXT['game-action'],
                boardStoneLabel: 0,
                userLevel: null,
                readyLoading: false,
                resultFunLoading: null,
                settingConfig: {},
                stompStatus: false,
                userLoginList: {},
                gift: 0
            };
        },
        methods: {
            ...playConfig.methods,
            ...gameJudgeConfig.methods,
            ...gameReviewConfig.methods,
            sendByOwn(res){
                res = res || {}
                let {sender} = res
                return this.usercode == sender
            },
            sendByPhone(res){
                let {clientId} = res || {}
                return clientId == this.PARAMS.phone_clientId
            },
            async stompOnOpen(){
                this.stompStatus = true
                this.PARAMS.loading.close()
                if(!this.pageHasInit) return
                await this.gameroomInfoInit({source: 'stompOnOpen'})
                await this.dataLoadInitExtend()
                this.pageNowFunEmit('timeContinue')
                this.clientStatusCheck()
            },
            stompOnclose(){
                if(!this.gameResult){
                    this.PARAMS.loading.open({
                        message: '连接中断，重连中...'
                    })
                }
                this.stompStatus = false
                this.pageNowFunEmit('timePause')
            },
            addStompEventSet(){
                this.socketHand.addEventListener({type: 'onclose', name: 'game', callback: this.stompOnclose})
                this.socketHand.addEventListener({type: 'onopen', name: 'game', callback: this.stompOnOpen})
            },
            addStompEventRemove(){
                this.socketHand.removeEventListener({type: 'onclose', name: 'game'})
                this.socketHand.removeEventListener({type: 'onopen', name: 'game'})
            },
            async stompInit(){
                await this.socketHand.waitStompStatus()
                if(this.socketHand.stompClientGet()) this.stompState = true
            },
            pageNowFunEmit(fun, params){
                params = params || []
                if(this[fun]) return this[fun](...params)
            },
            async paramsInit(){
                // this.playerList = []
                this.roomUserList = []
                this.gameroomInfo = null
                await this.pageNowFunEmit('tryPlayOff')
                this.pageNowFunEmit('clearAllProbs')
                await this._common.nextTick.call(this)
            },
            async pageInit(){
                if(!this.gameroomId) return
                this.PARAMS.loading.open()
                this.$refs.gameLoading.open()
                
                let state = await this.gameConfigInit()
                if(!state) return
                await this.paramsInit()
                this.pageHasInit = false
                await this.dataLoadInit()
                this._userStatus.userStatusQuery()
                this.addStompEventSet()
                this.pageHasInit = true
                this.playerStateCheck()
                await this.userLevelSet()
                await this._common.nextTick.call(this)
                if(!this.gameSgfInfoSame){
                    this.gameSgfInfo = {}
                    
                }
                this.clientStatusCheck()
                this.PARAMS.loading.close()
                this.$refs.gameLoading.close()
            },
            async dataLoadInit(){
                await this.gameroomSubscribe()
                await this.gameroomInfoInit({init: true})
                await this.dataLoadInitExtend()
            },
            async dataLoadInitExtend(){
                await this.gameroomLogin()
                await this.gameroomPlayerInit()
                await this.gameroomUserInit()
            },
            async gameroomLogin(){
                await this._common.nextTick.call(this)
                let {role} = this
                let {gameroomId} = this
                let res = await this.api.gameroomLogin({gameroomId, role})
                return res
            },
            async gameroomLogout(id){
                id = id || this.gameroomId
                let res = await this.api.gameroomLogout(id)
                return res
            },
            async gameroomSubscribe(){
                let {gameroomId} = this
                this._eventBus.$emit('gameLinkTakeFun', {
                    fun: 'gameroomSubscribe',
                    data: {
                        gameroomId,
                        callback: this.gameroomMessage
                    }
                })
            },
            gameroomMessage(res){
                this.messageHand(res)
            },
            messageHand(res){
                res = res || {}
                if(res.code != 0){
                    this._errorHand.hand(res, 'game')
                }
                let {msg} = res
                switch(msg){
                    case 'MSG_GAMEROOM_GAME_CONFIG_SEND':
                        this.roomConfigSet(res)
                        break
                    case 'MSG_GAMEROOM_GAME_CONFIG_UPDATE':
                        this.roomConfigSet(res)
                        break
                    case 'MSG_GAMEROOM_GAME_CONFIG_ACCEPT':
                        this.configAcceptHand(res)
                        break
                    case 'MSG_GAMEROOM_LOGOUT':
                        this.userLogoutHand(res)
                        break
                    case 'MSG_GAMEROOM_LOGIN':
                        this.userLoginHand(res)
                        break
                    
                    case 'MSG_WSGAME_GEN_MOVE':
                        this.genmoveHand(res)
                        break
                    case 'MSG_WSGAME_SUBSCRIBE':
                        this.gameSubscribeSuccess(res)
                        break
                    case 'MSG_WSGAME_ACTION_SEND':
                        this.actionHand(res)
                        break
                    case 'MSG_WSGAME_ACTION_ACCEPT':
                        this.actionResultHand(res, 'accept')
                        break
                    case 'MSG_WSGAME_ACTION_REJECT':
                        this.actionResultHand(res, 'reject')
                        break
                    case 'MSG_WSGAME_BACK_MOVE':
                        this.genmoveHand(res)
                        break
                    case 'MSG_WSGAME_JUDGE_DATA':
                        this.applyJudgeDataHand(res)
                        break
                    case 'MSG_GAMEROOM_GAME_CONFIG_REJECT':
                        this.configRejectRule(res)
                        break
                    case 'MSG_WSGAME_END':
                        this.gameEndReceive(res)
                        break
                    case 'MSG_WSGAME_START':
                        this.gameStart()
                        break
                    case 'MSG_GAMEROOM_DISCONNECT':
                        this.userDisconnect(res)
                        break
                    case 'MSG_GAMEROOM_CONNECT':
                        this.userConnect_con(res)
                        break
                    case 'MSG_GAMEROOM_SUBSCRIBE':
                        this.userConnect_sub(res)
                        break

                    case 'MSG_WSGAME_DISCONNECT':
                        // this.userDisconnect(res)
                        break
                    case 'MSG_WSGAME_CONNECT':
                        this.gameStateSync(res)
                        break
                    case 'MSG_WSGAME_STATE':
                        // this.gameStateSync(res)
                        this.gameStateSyncByTime(res)
                        break
                    case 'MSG_GAMEROOM_GAME_CREATE':
                        break
                    case 'MSG_GAMEROOM_GAME_RENEW_SEND':
                        this.roomConfigSetForGame(res)
                        break
                    case 'MSG_GAMEROOM_GAME_RENEW_REJECT':
                        this.playAgainReject(res)
                        break
                    case 'MSG_GAMEROOM_GAME_RENEW_ACCEPT':
                        this.playAgainAccept(res)
                        break
                    case 'MSG_GAMEROOM_READY':
                        this.gameroomReadyMsg(res)
                        break
                    case 'MSG_GAMEROOM_UNREADY':
                        this.gameroomReadyMsg(res)
                        break
                    case 'MSG_WSGAME_SYNC':
                        this.gameSyncBySocket(res)
                        break
                }
            },

            gameroomInfoTimeoutEnd(){
                clearInterval(this.gameroomInfoInterval)
            },
            async gameroomInfoTimeoutStart(){
                this.gameroomInfoTimeoutEnd()
                let splitTime = this.roomInfoSplitTimeCul
                let t = splitTime * 1000
                this.gameroomInfoInterval = setInterval(async () => {
                    if(this.pageDestroy) return this.gameroomInfoTimeoutEnd()
                    this.gameroomInfoInit({noLoad: true, noError: true, source: 'gameroomInfoTimeoutStart'})
                }, t);
            },
            async gameroomInfoInit(opt){
                opt = opt || {}
                let {callback} = opt
                this.gameroomInfoTimeoutEnd()
                // let res = await this.api.getGameroomInfo(opt)
                // if(res){
                //     this.localTimeSetCheck(res)
                //     res = this.localTimeSet(res)
                //     this.roomInfoLastTime = parseInt((new Date()).getTime() / 1000)
                //     this.gameroomInfo = res
                //     this.statusCheck()
                //     this.checkUserStatus()
                // }
                let res = await this.gameroomInfoInitAction(opt)
                if(callback) callback(res)
                this.gameroomInfoTimeoutStart()
            },
            async gameroomInfoInitAction(opt){
                opt = opt || {}
                let {gameroomId} = this
                opt.gameroomId = gameroomId
                let res = await this.api.getGameroomInfo(opt)
                if(res){
                    this.localTimeSetCheck(res)
                    res = this.localTimeSet(res)
                    this.roomInfoLastTime = parseInt((new Date()).getTime() / 1000)
                    this.gameroomInfo = res
                    this.statusCheck()
                    this.checkUserStatus()
                }
                return res
            },
            async statusCheck(){
                if(this.roomIsEnded){
                    return this.closeRoomHand()
                }

                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                // if(inviteeUserCode && inviterUserCode && this.isCreatedStatus){
                // }
                if(this.isGameStatus) return this.enterGameAction()
                if(this.isConfigStatus) return this.enterConfigAction()
                if(this.isCreatedStatus) return this.enterCreatedAction()
            },
            enterGameAction(){},
            enterConfigAction(){},
            enterCreatedAction(){},
            localTimeSetCheck(info){
                if(!this.isGameing) return
                let {gameResult, blackUserCode, whiteUserCode, wsGameId, gameState = {}} = info.gameMetaDto || {}
                let {usercode} = this
                if(this.localTimeGet(wsGameId)) return
                if(gameResult || (usercode != blackUserCode && usercode != whiteUserCode)) return


                let blackTime = {
                    countdownTime: gameState.blackRemainCountdownTime,
                    countdownNum: gameState.blackRemainCountdownNum,
                    mainTime: gameState.blackRemainTime,
                }
                let whiteTime = {
                    countdownTime: gameState.whiteRemainCountdownTime,
                    countdownNum: gameState.whiteRemainCountdownNum,
                    mainTime: gameState.whiteRemainTime,
                }
                this.appData.setItem({
                    blackTime,
                    whiteTime,
                    gameId: wsGameId
                })
            },
            localTimeGet(gameId){
                if(!this.isGameingPath){
                    return 
                }
                let blackTime = this.appData.getItem('blackTime')
                let whiteTime = this.appData.getItem('whiteTime')
                let gameIdLocal = this.appData.getItem('gameId')
                if(gameIdLocal != gameId){
                    this.pageNowFunEmit('localTimeClear')
                    return null
                }
                return {blackTime, whiteTime}
            },
            localTimeSet(info){
                if(!this.isGameingPath){
                    return info
                }
                let {gameResult, blackUserCode, whiteUserCode, wsGameId} = info.gameMetaDto
                if(gameResult){
                    return info
                }
                let timeData = this.localTimeGet(wsGameId)
                if(!timeData){
                    return info
                }
                let {usercode} = this
                let key = usercode == blackUserCode ? 'black' : usercode == whiteUserCode ? 'white' : null
                if(!key){
                    return info
                }
                let {gameMetaDto} = info || {}
                let {gameState = {}} = gameMetaDto || {}
                gameState[`${key}RemainTime`] = timeData[`${key}Time`].mainTime
                gameState[`${key}RemainCountdownTime`] = timeData[`${key}Time`].countdownTime
                gameState[`${key}RemainCountdownNum`] = timeData[`${key}Time`].countdownNum
                gameMetaDto.gameState = gameState
                info = {
                    ...info,
                    gameMetaDto
                }
                return info
            },
            
            async closeRoomHand(){
                let {roomIsEnded} = this
                if(roomIsEnded){
                    let message = null
                    if(!this.isGameing){
                        message = this._errorCode.game['game room has closed']
                    }
                    else{
                        if(this.isClosed){
                            message = this._errorCode.game['game room has closed']
                        }
                    }
                    if(message){
                        await this.messageNotice(message)
                        this.returnToGamezone()
                    }
                }
            },
            messageNoticeClose(){
                this._common.confirmPro.close()
            },
            async messageNotice(message){
                if(!message || this.pageDestroy) return
                return this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    className: 'room-close-confirm-pro',
                    buttons: [
                        {title: '确认', type: 'confirm'}
                    ]
                })
            },
            returnToGamezone(){
                this.routeWatchRemove()
                this._routeHand.backHand()
            },
            routeWatchSet(){
                // if(this.isWatcher) return
                this.routeWatchRemove()
                this._common.eventSet('gameBack', e => {
                    this.gameBack()
                })
            },
            routeWatchRemove(){
                this._common.eventRemove('gameBack')
            },
            async gameBack(){
                // if(this.gameIsEnd) return this.gameBackAction()
                // if(!this.$store.state.app.username) return this.gameBackAction()
                let userState = (this.isCreatedStatus && this.isOwner)
                if((this.isPlaying && this.isGameing) || userState){
                    let state = await this.routeChangeConfirm()
                    if(!state) return
                }
                this.gameBackAction()
            },
            async gameBackAction(){
                let userState = (this.isCreatedStatus && this.isOwner)
                if(userState) await this.closeRoomSend()
                else if(this.isPlaying && this.isGameing) await this.endGame()
                this.returnToGamezone()
            },
            async closeRoom(){
                if(!this.roomOwner) return this.closeRoomByUser()
                let state = await this.closeConfirm()
                if(!state) return
                await this.closeRoomSend()
                this.returnToGamezone()
            },
            closeRoomByUser(){
                this.gameBack()
            },
            async closeRoomSend(){
                let {gameroomId} = this
                let res = await this.api.closeGameroom({gameroomId})
                return res
            },
            async closeConfirm(){
                let message = this._errorCode.game['close room confirm']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '确定', type: 'confirm', label: 0},
                        {title: '取消', type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            async back(){
                if(this.gameIsEnd) return true
                if(!this.$store.state.username) return true
                let res = await this.routeChangeConfirm()
                if(!res) return false
                this.pageNowFunEmit('endGame')
                return true
            },
            async endGame(){
                if(!this.isPlaying) return true
                let {gameId: game_id} = this
                let color = this.userColor == 1 ? 'W' : 'B'
                let game_result = `${color}+R`
                let res = await this.api.endGame({game_id, game_result})
                return res
            },
            async routeChangeConfirm(){
                let message = this._errorCode.game['game room route change']
                let type = this.isGameing ? '对弈' : '协商'
                message = this._common.urlParse(message, {type})
                if(this.isCreatedStatus && this.isOwner) message = this._errorCode.game['game room create quit owner']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3 social-game-back',
                    buttons: [
                        {title: '确定', label: 0, type: 'confirm'},
                        {title: '取消', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            eventSet(){
                this.routeWatchSet()
                this.userInfoChange()
                this._userHand.on('userInfo', this.userInfoChange)
            },
            eventRemove(){
                this.routeWatchRemove()
                this._userHand.remove('userInfo')
                
            },
            async gameroomPlayerInit(opt){
                // opt = opt || {}
                // let {gameroomId: gameroom_id} = this
                // opt.gameroom_id = gameroom_id
                // let res = await this.api.gamePlayer(opt)
                // if(!res) return
                // this.playerList = res
            },
            async userListIntervalStart(){
                this.userListIntervalStop()
                let splitTime = this.userInfoSplitTime
                let t = splitTime * 1000
                this.userListInterval = setInterval(() => {
                    if(this.pageDestroy) return this.userListIntervalStop()
                    this.userListRefresh({source: 'interval'})
                }, t)
            },
            async userListIntervalStop(){
                if(this.userListInterval === null) return
                clearInterval(this.userListInterval)
            },
            async userListRefresh(opt){
                let {source} = opt || {}
                if(this.userRefreshDisabled && source != 'interval') return
                let page = this.userListPage, size = this.userListSize
                size = (Number(page) + 1) * size
                page = 0
                let res = await this.gameroomUserInit({noLoad: true, noError: true, page, size})
            },
            async checkUserStatus(){
                await this._common.nextTick.call(this)
                if(this.gameResult) return
                let val = this.gameState
                let {blackConnectionStatus, whiteConnectionStatus, blackUserCode, whiteUserCode} = val || {}
                if(!blackConnectionStatus && blackConnectionStatus !== undefined) this.userDisconnectByRoomInfo(blackUserCode)
                if(!whiteConnectionStatus && whiteConnectionStatus !== undefined) this.userDisconnectByRoomInfo(whiteUserCode)
            },
            userDisconnectByRoomInfo(userCode){
                this.$set(this.disconnectUser, userCode, {userCode})
            },
            async playerStateCheck(){
                if(this.roomIsEnded) return
                let {userCode: usercode} = this.rivalUserInfo
                if(!usercode) return
                let userInfo = await this.api.wsUserInfo({usercode})
                if(!userInfo) return
                if(userInfo.webConnectionStatus == this.ENUMS.CONNECTION_CLOSED && userInfo.appConnectionStatus == this.ENUMS.CONNECTION_CLOSED){
                    let {userCode} = userInfo
                    if(!this.disconnectUser[userCode]) this.userDisconnect({code: 0, data: userInfo})
                }else{
                    this.userConnect(userInfo)
                }
            },
            async userLevelSet(type){
                this.userLevel = await this.userLevelGet()
            },
            async userLevelGet(opt){
                opt = opt || {}
                let {gameroomId: gameroom_id} = this
                opt.gameroom_id = gameroom_id
                let {usercode} = this
                let res = await this.api.gamePlayer(opt)
                if(!res) return null
                let level = 0
                for(let i in res){
                    if(res[i].userCode == usercode){
                        level = res[i].level
                        break
                    }
                }
                return level
            },
            clientStatusCheck(res){
                if(this.isWatcher) return
                let {gameState, userColor, gameroomInfoCul: {gameroomStatus: status}} = this
                let key = userColor == 1 ? 'black' : userColor == -1 ? 'white' : null
                if(gameState[`${key}RemainOfflineTime`] === undefined || status != this.ENUMS.ROOM_PLAYING){
                    return
                }
                let time = gameState[`${key}RemainOfflineTime`]
                let amountTime = gameState.offlineTime
                if(time >= amountTime) return
                let message = this._errorCode.game['offline time notice']
                let {minute, second} = this.remainOfflineTime
                message = this._common.urlParse(message, {minute, second})
                let className = 'invite-toast-box'
                this.$toast({
                    message,
                    className
                })
            },

            async boardLoaded(board){
                this._board = this.$refs['board']
                this.boardState = true
            },
            emitEvent(opt){
                this._common.emitEvent.call(this, opt)
            },
            async gameConfigInit(num){
                num = num || 1
                if(num >= 3){
                    this.$refs.gameLoading.close()
                    this.PARAMS.loading.close()
                    let state = await this.gameConfigErrorConfirm()
                    if(!state){
                        
                        await this.endGame(this.PARAMS.social_game_none_result)
                        this.quitRoom()
                        return
                    }
                    num = 1
                }
                this.$refs.gameLoading.open()

                let res = await this.api.configLoad('game')
                // let res = false
                if(!res){
                    num++
                    return this.gameConfigInit(num)
                }
                this.gameList = res
                return res
            },
            async gameConfigErrorConfirm(){
                let message = this._errorCode.game['network error']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                    {title: '重试', label: 0, type: 'confirm'},
                    {title: '退出', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            async gameroomUserInit(opt){
                let {gameroomId} = this
                this.roomUserList = []
                opt = opt || {}
                opt.gameroomId = gameroomId
                opt.page = opt.page === undefined ? this.userListPage : opt.page
                opt.size = opt.size || this.userListSize
                // this.userListIntervalStart()
                let res = await this.api.getRoomUserList(opt)
                if(!res) return
                this.roomUserList = res
            },
            async userLoginHand(res){
                let {sender} = res
                this.setUserCount(res)
                if(this.sendByOwn(res) && this.sendByPhone(res)) return this.userLoginByPhone(res)
                if(this.sendByOwn(res)) return
                await this.userListRefresh()
                // this.loginDataHandler(res)

                // let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                // if(!this.isWatcher && (inviteeUserCode == sender || inviterUserCode == sender)){
                    // this.$toast({message: this._errorCode.game['opponent login']})
                // }
            },
            async userLogoutHand(res){
                this.setUserCount(res)
                if(this.sendByOwn(res)) return
                let {sender} = res
                this.logoutDataHandler(res)
                await this.userListRefresh()
                if(sender == this.ownerUserCode) return this.gameroomInfoInit()
                this.playAgainLogoutCheck(res)

            },
            loginDataHandler(res){
                let {data: {userInfo} = {}} = res
                let {userCode} = userInfo || {}
                if(!userCode) return
                this.userLoginList[userCode] = userInfo
            },
            logoutDataHandler(res){
                if(this.sendByOwn(res)) return
                let {sender} = res
                let {data: {userInfo} = {}} = res
                let {userCode, gameroomId} = userInfo || {}
                if(!userCode || !this.roomUserListObject[userCode] || gameroomId != this.gameroomId) return
                // delete this.userLoginList[userCode]
                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                if(!this.isWatcher && (inviteeUserCode == sender || inviterUserCode == sender)){
                    this.$toast({message: this._errorCode.game['opponent logout']})
                }
            },
            userLoginByPhone(){},
            setUserCount(res){
                let {data: {userCount}} = res
                let info = this.gameroomInfo
                if(!info) return
                info.gameroomStateDto = info.gameroomStateDto || {}
                info.gameroomStateDto.onlineUserCount = userCount
                this.gameroomInfo = info
            },
            async playAgainLogoutCheck(res){
                let {sender} = res
                if(!this.$refs['actionConfirm'] || 
                !this.$refs['actionConfirm'].action || 
                this.$refs['actionConfirm'].actionType != this.ENUMS.ACTION_PLAY_AGAIN ||
                this.$refs['actionConfirm'].sender != sender) return
                this.$refs['actionConfirm'].close()
                let message = this._errorCode.game['user reject game rule']
                this.$toast({message})
            },
            userConnect_con(res){
                res = res || {}
                if(res.code != 0) return
                let {
                    data: {
                        userInfo = {},
                        userCount = 0
                    }
                } = res
                this.userConnect(userInfo)
                this.setUserCount({data: {userCount}})
            },
            userConnect_sub(res){
                res = res || {}
                if(res.code != 0) return
                let {data} = res
                this.userConnect(data)
            },
            userConnect(data){
                let {userCode, resource} = data || {}
                if(!userCode) return
                this.$delete(this.disconnectUser, userCode)
                if(resource != 'info') this.gameroomInfoInit({noLoad: true, noError: true, source: 'userConnect'})
            },
            userDisconnect(res){
                res = res || {}
                if(res.code != 0) return
                let {
                    data: {
                        userInfo = {},
                        userCount = 0
                    }
                } = res
                let {userCode} = userInfo
                this.$set(this.disconnectUser, userCode, userInfo)
                this.setUserCount({data: {userCount}})
            },
            userInfoChange(){
                this.userInfo = this.$store.state.app
            },
            async otherUserInfoLoad(){
                let res = await this.api.userAccountOther({user_code: this.otherUsercode})
                if(!res) return
                this.otherUserInfo = res
            },
            unsubscribe(){
                let list = [
                    'gameUnsubscribe',
                    'gameroomUnsubscribe'
                ]
                for(let i in list){
                    let fun = list[i]
                    this._eventBus.$emit('gameLinkTakeFun', {
                        fun,
                    })
                }
            },
            async quitSet(){
                if(this.quitSetAction) return
                await this.gameroomLogout()
                return true
            },
            async gameTakeAction(){
                this._userStatus.userStatusChange = this.userStatusChange
                await this.quitSet()
                this.quitSetAction = true
                await this._userStatus.setUserStatus({type: 'idle', source: 'room: idle gameTakeAction'})
                await this._common.nextTick.call(this)
                let message = this._errorCode.game['game has multied by app']
                this.messageNotice(message)
            },
            timeChange(type, val){
                if(!this.pageHasInit || !this.timeHasInited || this.gameResult) return
                val = val || {}
                let key = `${type}RemainTime`
                if(!this[key]) return
                this[key] = {
                    ...this[key],
                    ...val
                }
                this.localTimeSave()
            },
            localTimeSave(){
                if(this.gameResult || this.isWatcher || !this.isGameing || !this.timeHasInited) return
                let blackTime = this.blackRemainTimeCul
                let whiteTime = this.whiteRemainTimeCul
                this.appData.setItem({
                    blackTime,
                    whiteTime,
                    gameId: this.gameId
                })
            },
            async gameroomReady(){
                // if(this.isReady) return
                let {gameroomId} = this
                this.readyLoading = true
                let fun = 'gameroomReady'
                if(this.isReady) fun = 'gameroomUnready'
                let res = await this.api[fun]({gameroomId, noLoad: true})
                this.readyLoading = false
                // if(!res) return
                // this.gameroomInfoInit({noLoad: true, noError: true})
            },
            async gameroomReadyMsg(res){
                await this.gameroomInfoInit({noLoad: true, noError: true})
            },
            async timeInit(){
                if(!this.isGameing || this.gameResult) return
                this.$refs['blackTime'].reset()
                this.$refs['whiteTime'].reset()
                await this._common.nextTick.call(this)
                this.timeHasInited = true
            },
            async gameStatusChange(){
                this.gameroomPlayerInit()
                // if(this.isPlaying) this.gameReadyCheck()
                await this._common.nextTick.call(this)
                if(this.isGameStatus) this.timeLocalInit()
            },
            async gameStart(){

                await this.gameroomInfoInit({source: 'gameStart'})
                await this.gameroomPlayerInit()
                if(this.gameResult) return
                
                await this.userColorNotice()
                // this._voiceHand.normalPlay('game start word')
                this.playGameStart()
                let {gameState: {mainTime}} = this
                if(mainTime !== undefined && mainTime == 0){
                    await this._common.settimeout(1000)
                    this._voiceHand.normalPlay('count down start')
                }
            },
            
            async userColorNotice(){
                let {userColor} = this
                if(!userColor) return
                await this.$refs['gameColorNoticePro'].open()
            },
            gameStateSync(res){
                if(this.sendByOwn(res) || this.gameResult) return
                let {data} = res || {}
                if(!data) return
                data = this.genmoveGameStateParse(data)
                this.timeUpdateByGameState(data)
            },
            genmoveGameStateParse(data){
                if(this.isWatcher) return data
                let color = this.userColor
                color = color == 1 ? 'black' : 'white'
                let timeData = this[`${color}RemainTimeCul`]
                if(timeData.mainTime !== undefined) data[`${color}RemainTime`] = timeData.mainTime
                if(timeData.countdownTime !== undefined) data[`${color}RemainCountdownTime`] = timeData.countdownTime
                if(timeData.countdownNum !== undefined) data[`${color}RemainCountdownNum`] = timeData.countdownNum
                
                
                return data
            },
            async gameEndReceive(res){
                this.pageNowFunEmit('endGameSet', [res])
                await this.gameroomInfoInit({source: 'gameEndReceive'})
                this.gameroomPlayerInit({noLoad: true, noError: true})
                if(!this.gameResult) return
                if(!this.isRR){
                    let type = this.userWin ? 'game win' : 'game lose'
                    this._voiceHand.normalPlay(type)
                }
                await this._common.nextTick.call(this)
                if(this.invalidGame){
                    let message = this._errorCode.game['invalid game']
                    message = this._common.urlParse(message, {moveNum: this.PARAMS.game_min_move_num})
                    this.$toast({
                        message
                    })
                }
                this.gameResultOpen()
                this._userStatus.userStatusQuery()
                this.gameGiftGet()
            },
            // gameResultOpen(){
            //     this.$toast({
            //         message: `对局结束，结果：${this.gameResult}`
            //     })
            // },
            gameResultOpen(){
                // if(this.invalidGame || this.isWatcher) return
                if(this.invalidGame) return
                this.resultFunLoading = null
                if(this.$refs['gameResultWindow'] && !this.$refs['gameResultWindow'].active) this.$refs['gameResultWindow'].open()
            },
            gameResultClose(){
                if(this.$refs['gameResultWindow']) this.$refs['gameResultWindow'].close()
            },
            async setStatusIdle(){
                if(this.isWatcher) return
                this._userStatus.userStatusQuery()
            },
            roomInfoUpdate(){
                let {gameInfo: info} = this
                let {gameState = {}} = info
                let {blackRemainTime, whiteRemainTime, situationLocal} = this
                gameState.blackRemainTime = blackRemainTime.mainTime
                gameState.blackRemainTime = blackRemainTime.mainTime

                gameState.blackRemainCountdownTime = blackRemainTime.countdownTime
                gameState.whiteRemainCountdownTime = whiteRemainTime.countdownTime

                gameState.blackRemainCountdownNum = blackRemainTime.countdownNum
                gameState.whiteRemainCountdownNum = whiteRemainTime.countdownNum

                gameState.situation = situationLocal

                info.gameState = gameState
                this.gameroomInfo.gameMetaDto = info
            },
            async genmoveHand(res){
                this.gameroomInfoTimeoutEnd()
                res = res || {}
                let {data} = res
                data = data || {}
                let {situation} = data
                let info = this.gameroomInfo || {}
                let meta = info.gameMetaDto || {}
                data = this.genmoveGameStateParse(data)
                await this.timeUpdateByGameState(data, {type: 'genmove', sendByOwn: this.sendByOwn(res)})
                meta.gameState = data
                info.gameMetaDto = meta
                this.gameroomInfo = info
                await this.boardSyncWatch()
                await this._common.nextTick.call(this)
                this.timeStartCon()
                this.gameroomInfoTimeoutStart()
                // this.captureGameResultCheck()
            },
            setHandicap(val){
                if(!this.$refs['board']) return
                val = val || 0
                if(!val){
                    this.clearBoard()
                    return
                }
                this.setHnadicapAction(val)
            },
            async setHnadicapAction(val){
                let handicap = val
                this.clearBoardAction()
                let moves = this.gameApi.getHandicapMoves(handicap, this.boardSize)
                for(var i in moves){
                    let coor = this.$refs['board'].board.coor1to2(moves[i])
                    this.$refs['board'].play(coor.x, coor.y)
                    if(i < moves.length - 1) this.$refs['board'].passAction()
                }
            },
            clearBoardAction(){
                if(!this.$refs['board']) return
                this.$refs['board'].clearBoard()
            },
            changeLabel(label){
                if(this._common.isBool(label)){
                    label = label ? 1 : 0
                }else{
                    label = this.boardStoneLabel
                    label = label == 0 ? 1 : 0
                }
                this._board.changeLabel(label)
            },
            gameRemainTimeParse(info){
                info = this._common.deepCopy(info)
                let {
                    blackRemainTime,
                    whiteRemainTime,

                    blackRemainCountdownTime,
                    whiteRemainCountdownTime,

                    blackRemainCountdownNum,
                    whiteRemainCountdownNum,
                } = info
                blackRemainTime = blackRemainTime / 1000
                whiteRemainTime = whiteRemainTime / 1000

                blackRemainCountdownTime = blackRemainCountdownTime / 1000
                whiteRemainCountdownTime = whiteRemainCountdownTime / 1000

                return {
                    blackRemainTime,
                    whiteRemainTime,

                    blackRemainCountdownTime,
                    whiteRemainCountdownTime,

                    blackRemainCountdownNum,
                    whiteRemainCountdownNum,
                }
            },
            async resign(){

                if(this.resignDisabled) return
                let subMessage = this.pageText.t13
                let message = this.pageText.t11
                // this.timeStop()
                let res = await this.normalConfirmPro({message, confirmType: 'c2', defineClass: 'resign'})
                // if(!res) return this.timeStartCon()
                if(!res) return
                this.resignAction()
            },
            resignAction(){
                let {userColor} = this
                let winColor = userColor == 1 ? 'white' : userColor == -1 ? 'black' : null
                if(!winColor) return
                let result = this._common.getResultKey({win: winColor})
                if(!result) return
                this.gameResultSet(result)
            },
            async normalConfirmPro(opt){
                let {
                    message,
                    confirmText = this.pageText.t16,
                    cancelText = this.pageText.t17,
                    defineClass,
                } = opt || {}
                defineClass = defineClass || ''
                defineClass += ' game'
                let res = await this._common.confirmPro.open({
                    ...opt,
                    message,
                    defineClass,
                    buttons: [
                        {title: confirmText, type: 'confirm', label: 0},
                        {title: cancelText, type: 'cancel', label: 1},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            
            audioPlay(type, params){
                type = type || ''
                this._voiceHand.normalPlay(type)
            },
            gameStateSyncByTime(res){
                if(this.sendByOwn(res) || this.gameResult) return
                let {data} = res || {}
                if(!data) return
                data = this.genmoveGameStateParse(data)
                this.gameStateSyncAction(data)
                this.readStartHandler(data)
            },
            readStartHandler(data){
                let {colorNow, userColor} = this
                if(colorNow == userColor){
                    return
                }
                let key = colorNow == 1 ? 'black' : 'white'
                let remainMainTime = data[`${key}RemainTime`]
                let RemainCountdownTime = data[`${key}RemainCountdownTime`]
                let RemainCountdownNum = data[`${key}RemainCountdownNum`]
                let mainTime = data.mainTime
                let countdownNum = data.countdownNum
                let countdownTime = data.countdownTime
                if(mainTime == 0 && colorNow == 1){
                    return
                }
                if(remainMainTime > 0 || RemainCountdownTime < countdownTime || RemainCountdownNum < countdownNum) return
                this.audioPlay('count down start')
            },
            playAgainSet(){

            },
            async roomConfigSetForGame(res){
                if(this.isWatcher) return
                if(this.sendByOwn(res)) await this.gameroomInfoInit({noLoad: true})
                let state = await this.playAgainConfirm(res)
                this.playAgainConfirmSet(res, state)
            },
            async playAgainConfirm(res){
                if(this.sendByPhone(res) && this.sendByOwn(res)){
                    this.gameroomInfoInit()
                    return
                }
                if(this.sendByOwn(res)){
                    let message = this._errorCode.game['play again wait']
                    this.$toast({message})
                    return
                }
                let list = this.playerList
                let {sender} = res
                let userInfo = {}
                for(let i in list){
                    if(list[i].userCode == sender){
                        userInfo = list[i]
                        break
                    }
                }
                let {level, nickname} = userInfo
                let levelData = this._common.getLevel(level)
                let waitingText = this._errorCode.game['play again wait']
                let confirmText = this._errorCode.game['play again confirm']
                confirmText = this._common.urlParse(confirmText, {
                    nickname: nickname,
                    level: levelData.name
                })
                let state = await this.$refs['actionConfirm'].open({
                    sender,
                    waitingText,
                    confirmText,
                    actionType: this.ENUMS.ACTION_PLAY_AGAIN
                })
                return state
            },
            playAgainConfirmSet(res, state){
                if(this.sendByOwn(res)) return
                this.playAgainConfigSet(res, state)
            },
            async playAgainConfigSet(res, state){
                let user_state = await this.playAgainResponseStatusCheck(res)
                if(!user_state){
                    if(state){
                        let message = this._errorCode.game['user reject game rule']
                        this.$toast({
                            message
                        })
                    }
                    
                    return
                }
                if(!state){
                    let {data: {gameConfig = {}} = {}} = res
                    let {gameroomId} = this
                    let data = await this.api.rejectPlayAgain(gameConfig)
                    return data
                }
                this.playAgainConfigAccept(res)
            },
            async playAgainResponseStatusCheck(res, state){
                let {sender} = res
                if(!sender) return false
                let info = await this.api.wsUserInfo({usercode: sender})
                if(!info) return false
                return this._userStatus.inviteAvai_status(info)
            },
            async playAgainConfigAccept(res){
                let userStatus = await this.playAgainUserStatusCheck()
                if(!userStatus){
                    let message = this._errorCode.game['user reject game rule']
                    this.$toast({
                        message
                    })
                    return
                }
                let {data: {gameConfig = {}} = {}} = res
                this.gameUnsubscribe()
                return this.api.acceptPlayAgain(gameConfig)
            },
            async playAgainUserStatusCheck(){
                let state = await this.playAgainCheckAction()
                return state
            },
            async playAgainReject(res){
                await this.gameroomInfoInit({noLoad: true})
                this.playAgainAcceptConfig(res, false)
            },
            async playAgainAcceptConfig(res, state){
                if(!this.sendByOwn(res) && !this.isWatcher){
                    let msg = state ? 'accept' : 'reject'
                    let message = this._errorCode.game[`${msg} play again`]
                    this.$refs['actionConfirm'].setStatus(msg, message)
                }
                // if(state){
                //     let pageStatus = 'play'
                //     await this.refreshPage({
                //         path: 'gamePlayFull',
                //         items: {
                //             pageStatus
                //         }
                //     })
                // }
                
                await this.gameSubscribe()
                await this._common.settimeout(1000)
                this.gameReadyCheck()
            },

            async playAgainAccept(res){
                this.$refs['actionConfirm'].close()
                let state = this.playAgainAcceptCheck(res)
                if(!state){
                    this.messageNotice(this._errorCode.game['play again by phone'])
                    return this.quitRoom()
                }
                this.tryPlayOff()
                this.gameResultClose()

                await this._common.nextTick.call(this)
                this.userLevel = null
                await this.userLevelSet()
                this.pageNowFunEmit('clearAllProbs')
                await this.gameroomInfoInit({noLoad: true})
                await this.gameroomPlayerInit({noLoad: true})

                this.playAgainAcceptConfig(res, true)
                this.pageNowFunEmit('playAgainStart')
                this._userStatus.userStatusQuery()
            },
            playAgainAcceptCheck(res){
                let {data: {gameConfig: {blackClientId, blackUserCode, whiteClientId, whiteUserCode} = {}} = {}} = res
                let {usercode} = this
                let {web_clientId: clientId} = this.PARAMS
                if(blackClientId !== undefined && blackUserCode == usercode && blackClientId != clientId) return false
                if(whiteClientId !== undefined && whiteUserCode == usercode && whiteClientId != clientId) return false
                return true

            },
            async quitRoom(){
                await this.quitSet()
                this.returnToGamezone()
            },
            funHand(val){
                val = val || {}
                let {fun} = val
                if(!this[fun]) return
                this[fun](val)
            },
        
        
            async gameSgfInfoSet(opt){
                if(this.invalidGame) return
                if(this.gameSgfInfoSame && this.gameSgfInfo.gameroomId) return
                if(this.gameResult){
                    await this.gameSgfInfoSetAction()
                }
            },
            async gameSgfInfoSetAction(){
                let gameroomId = this.gameroomId
                let {gameRound} = this
                let res = await this.api.gameSgf({gameroomId, gameRound})
                if(res) this.gameSgfInfo = {gameroomId, ...res, gameRound}
                return res
            },
            async gameInfoUpdate(opt){
                let {callback} = opt || {}
                await this.gameSgfInfoSetAction()
                if(callback) callback()
            },
            async gameSgfInfoSetCallback(opt){
                let {callback} = opt || {}
                await this.gameSgfInfoSet()
                if(callback) callback()
            },
            async ownSgfSetCallback(opt){
                let {callback} = opt || {}
                if(this.gameSgfInfo.gameroomId != this.gameroomId) await this.gameSgfInfoSet()
                await this._common.nextTick.call(this)
                if(!this.isOwnSgf) await this.copyGame()
                await this._common.nextTick.call(this)
                callback()

            },
            async copyGame(){
                let {sgfMeta} = this
                if(this.isOwnSgf) return sgfMeta
                if(!sgfMeta || !sgfMeta.id) return false
                let res = await this.api.replicateGame({id: sgfMeta.id})
                if(res){
                    let info = this.gameSgfInfo || {}
                    let content = info.content || []
                    content.push(res)
                    info.content = content
                }
                return res
            },
        
            // action type handler
            async sendActionType(opt){
                let {action_type, status} = opt || {}
                status = status ? true : false
                let {gameId: game_id} = this
                if(status) this.pageNowFunEmit('closeAllProbs')
                let res = await this.api.sendResultAction({type: status, game_id, action_type})
                return res
            },
            actionHand(res){
                let {data: {actionType} = {}, code, sender} = res || {}
                if(code != 0) return
                if(!this[`actionHand_${actionType}`]) return
                if(this.timePause) this.timePause()
                if(this.isWatcher) return
                this[`actionHand_${actionType}`](res.data, sender)
            },
            async actionEndCheck(opt){
                if(!this.$refs['actionConfirm'] || this.$refs['actionConfirm'].status) return
                let {status, type} = this
                let message = this._errorCode.game[`action await time out`]
                this.$refs['actionConfirm'].setStatus('reject', message)
            },
            async actionHand_10(data, sender){
                let {usercode} = this
                let waitingText = this._errorCode.game['undo waiting']
                let confirmText = this._errorCode.game['undo confirm receive']
                let res = await this.$refs['actionConfirm'].open({
                    sender,
                    waitingText,
                    confirmText
                })
                let status = res, type = 'undo'
                let action_type = this.ENUMS.ACTION_UNDO
                if(sender != usercode) return this.sendActionType({status, action_type})
                this.actionEndCheck({status, type})
            },
            async actionResult_20(res, type){
                if(this.sendByOwn(res)) return
                let message = this._errorCode.game[`apply judge confirm ${type}`]
                this.$refs['actionConfirm'].setStatus(type, message)
                if(type == 'accept') this.applyJudgeAction()
            },
            applyJudgeDataHand(res){
                if(this.isWatcher) return
                res = res || {}
                if(res.code != 0) return
                this.applyJudgeInit()
                this.areaHandledcnn(data, this.now)
                this.applyJudge.orignalData = this.GLOBAL.deepCopy({data, color: this.now})
                let {winrate, delta} = data
                this.applyJudge.active = true
                this.applyJudge.winrate = winrate
                this.applyJudge.delta = delta
                this.applyJudge.sender = sender
            },
            actionResult_40(res, type){
                type = type == 'accept' ? true : false
                this.applyJudgeResultReceive(res, type)
            },
            async configRejectRule(res){
                if(this.isGameing){
                    await this.gameroomInfoInit()
                }
                if(this.isWatcher) return
                if(this.isGameing) return this.playAgainAcceptConfig(res, false)
                if(this.sendByOwn(res)) return
                let message = this._errorCode.game['user reject game rule']
                await this.messageNotice(message)
                this.quit()
            },
            async actionHand_30(data, sender){
                let {usercode} = this
                let waitingText = this._errorCode.game['apply draw waiting']
                let confirmText = this._errorCode.game['apply draw confirm receive']
                let res = await this.$refs['actionConfirm'].open({
                    sender,
                    waitingText,
                    confirmText
                })
                let status = res, type = 'draw'
                let action_type = this.ENUMS.ACTION_DRAW
                if(sender != usercode) return this.sendActionType({status, action_type})
                this.actionEndCheck({status, type})
            },

            async actionResult_30(res, type){
                if(this.sendByOwn(res)) return
                let message = this._errorCode.game[`apply draw confirm ${type}`]
                this.$refs['actionConfirm'].setStatus(type, message)
                this.applyDrawAction(type)
            },
            actionResultHand(res, type){
                if(!this.sendByOwn(res) && type == 'reject') this.rejectVoicePlay(res)
                if(!this.$refs['actionConfirm']) return
                let {data: {actionType} = {}, code, sender} = res || {}
                if(code != 0) return
                if(this.timeContinue){
                    if((this.ENUMS.ACTION_JUDGE != actionType && this.ENUMS.ACTION_RESULT != actionType) || type == 'reject') this.timeContinue()
                }
                if(this.isWatcher) return
                let fun = `actionResult_${actionType}`
                if(!this[fun]) return
                this[fun](res, type)
            },
            rejectVoicePlay(){
                this._voiceHand.playAction('actionReject')
            },
            async actionResult_10(res, type){
                if(this.sendByOwn(res)) return
                let message = this._errorCode.game[`undo confirm ${type}`]
                this.$refs['actionConfirm'].setStatus(type, message)
                this.undoAction(type)
            },
            openSetting(){
                this.$refs.setting.open()
            },
            settingInit(){
                let config = this._userHand.getUserSetting()
                this.settingConfig = config
                this.cancelPlay()
            },
            settingChange(){
                this.settingInit()
            },
            cancelPlay(){
                if(!this._board || !this._board.cancelPlay) return
                this._board.cancelPlay()
            },
            async gameReview(){
                this.resultFunLoading = 'review'
                await this.gotoGameReview()
                this.resultFunLoading = null
            },
            gameSyncBySocket(res){
                let {moveNum, wsGameId} = res.data || {}
                if(moveNum === undefined || wsGameId === undefined || wsGameId != this.gameId) return
                this.gameSyncBySocketAction(moveNum)
            },
            gameSyncBySocketAction(moveNum){
                this.gameroomInfoTimeoutEnd()
                let {situation} = this
                if(situation.length == moveNum) return this.gameroomInfoTimeoutStart()
                console.log('game sync error !', situation.length, moveNum)
                this.gameroomInfoInit({noLoad: true})
            },
            gameTakeCheckByComponent(gameroomId){
                if(gameroomId !== undefined && this.gameroomId == gameroomId){
                    this.gameroomInfoInit({noLoad: true})
                    return 0
                }
                return this.roomIsEnded
            },
            async tasteAgain(){
                // boardSize, komi, rule, handicap, type = rule, label: gamename, 
                let {boardSize, komi, rule, handicap, gamename} = this.gameMeta || {}
                let type = rule == 'capture' ? rule : 'play', label = gamename
                let config = this.gameSelected || {}
                let res = await this._common.tasteWindow.open({...config, type, label, boardSize, komi, rule, handicap, gamename})
                if(!res) return
                await this._common.nextTick.call(this)
                this.tasteAgainSuccess()
            },
            async tasteAgainSuccess(){
                this.tryPlayOff()
                this.gameResultClose()
                await this._common.nextTick.call(this)
                this.userLevel = null
                await this.userLevelSet()
                this.pageNowFunEmit('clearAllProbs')
                await this.gameroomInfoInit({noLoad: true})
                await this.gameroomPlayerInit({noLoad: true})
            },
            async gameGiftGet(){
                let res = await this.gameApi.gameGift()
                this.gift = res || 0
            },
        },
        created() {
            this.settingInit()
            this.eventSet()
            this.stompInit()
            this._userStatus.gameTakeCheckByComponent = this.gameTakeCheckByComponent
        },
        components: {
            board,
            preGameWindow,
            actionConfirm,
            gameTime,
            gameResult,
            gameColorNoticePro,
            setting,
            optionsMask,
            loading
        },
        computed: {
            ...playConfig.computed,
            ...gameJudgeConfig.computed,
            ...gameReviewConfig.computed,
            roomUserListObject(){
                let {roomUserList: list} = this
                let key = 'userCode'
                let data = this._common.transformToObject({list, key})
                return data
            },
            isTaste(){
                let {gameMetaType: gameType} = this
                return this._common.isTaste(gameType)
            },
            gameroomUserStatus(){
                let {inviterUserCode, inviteeUserCode} = this.gameroomState
                let {usercode} = this
                let key = null
                if(inviterUserCode == usercode) key = 'inviter'
                else if(inviteeUserCode == usercode) key = 'invitee'
                if(!key || this.gameroomState[`${key}UserStatus`] === undefined) return 0
                return this.gameroomState[`${key}UserStatus`]
            },
            gameIsReady(){
                let {gameroomUserStatus: status} = this
                return status == this.ENUMS.GAME_ROOM_USER_CONFIGURED
            },
            whiteConnectionStatus(){
                let {whiteConnectionStatus} = this.gameState
                return whiteConnectionStatus
            },
            blackConnectionStatus(){
                let {blackConnectionStatus} = this.gameState
                return blackConnectionStatus
            },
            rule(){
                let info = this.gameMeta || {}
                let {rule} = info
                if(!rule) rule = this.gameRuleByConfig
                return rule
            },
            gameRuleByConfig(){
                return this.isCapture ? 'capture' : 'chinese'
            },
            roomInfoSplitTimeCul(){
                let {roomInfoSplitTime: time, userInfoSplitTime: longTime} = this
                if(this.gamePlaying && !this.isWatcher) return time
                return longTime
            },
            startMoveNum(){
                let {boardInitMoves: moves} = this
                return moves.length
            },
            playerList(){
                let {gameMetaCul: info} = this
                let list = [
                    {
                        nickname: info.blackNickname, level: info.blackLevel, userCode: info.blackUserCode, photoFile: info.blackPhotoFile
                    },
                    {
                        nickname: info.whiteNickname, level: info.whiteLevel, userCode: info.whiteUserCode, photoFile: info.whitePhotoFile
                    }
                ]
                return list
            },
            judgeDisabled(){
                let {boardSize, positionCul} = this
                let list = this._common.gameJudgeMoveNum
                let num = list[boardSize] || 0
                if(positionCul >= num) return false
                let message = this.pageText.judgeDis
                message = this._common.urlParse(message, {num})
                // return this.pageText[`judge_${boardSize}`]
                return message
            },
            continueText(){
                return this.gameResult ? '退出' : '继续下棋'
            },
            // gift(){
            //     return null
            // },
            winColor(){
                if(this.invalidGame || this.isRR) return 0
                let win = this._common.resultWinLabel(this.gameResult)
                let data = win == 'b' ? 1 : win == 'w' ? -1 : 0
                return data
            },
            confirmPlaySwitch(){
                let {confirmPlay} = this.settingConfig
                if(confirmPlay === undefined) return true
                confirmPlay = Number(confirmPlay)
                if(isNaN(confirmPlay)) return true
                return confirmPlay ? true : false
            },
            gameRound(){
                let {gameMeta: {gameRound} = {}} = this
                return gameRound || 1
            },
            sgfMeta(){
                let {gameroomInfoCul: gameroomInfo, roomUserList: userList} = this
                let {content: list = [], gameroomId} = this.gameSgfInfo || {}
                let rd = {}
                let {usercode} = this
                if(gameroomId != this.gameroomId) return rd
                rd = null
                let blackGameMeta = {}
                for(let i in list){
                    let {blackUserCode, userCode} = list[i]
                    if(blackUserCode == userCode) blackGameMeta = list[i]
                    if(userCode == usercode){
                        rd = list[i]
                        break
                    }
                }
                let data = rd || blackGameMeta
                return {...data, gameroomInfo, userList}
            },
            gameMetaType(){
                let meta = this.gameMeta || {}
                let {gameType} = meta
                return gameType
            },
            gameJudgeDisplay(){
                return this.isPlay && !this.gameResult
            },
            passDisplay(){
                return this.isPlay && !this.gameResult
            },
            undoDisplay(){
                return !this.gameResult
            },
            drawDisplay(){
                return !this.gameResult && this.isPlay
            },
            configUserInfoList(){
                let {blackUserInfo, whiteUserInfo} = this
                let data = [
                ]
                if(blackUserInfo && blackUserInfo.userCode) data.push(blackUserInfo)
                if(whiteUserInfo && whiteUserInfo.userCode) data.push(whiteUserInfo)
                
                return data
            },
            roomOwner(){
                return this.ownerUserCode == this.usercode
            },
            playerListObj(){
                let {playerList: list} = this
                let data = {}
                for(let i in list){
                    let {userCode} = list[i]
                    data[userCode] = list[i]
                }
                return data
            },
            funListCul(){
                let {funList: list} = this
                return this._common.funhandAct.call(this, list)
            },
            playAgainDisplay(){
                if(this.gameResult && this.isGameStatus) return true
                return false
            },
            resignDisplay(){
                if(!this.gameResult && this.isGameStatus) return true
                return false
            },
            countdownDisabled(){
                if(this.gameResult) return true
                if(this.isCreatedStatus) return true
                return false
            },
            resignDisabled(){
                return this.gameResult ? true : false
            },
            positionCul(){
                let minNum = this.boardMinNum
                return this.position - minNum
            },
            captureNum(){
                let {captureNum: data} = this.gameSelected
                if(data !== undefined) return data
                return 5
            },
            moveNum(){
                let {moveNum: data} = this.gameSelected
                if(data !== undefined) return data
                return 60
                // return 6
            },
            blackRemainTimeCul(){
                let {blackRemainTime} = this
                let {mainTime, countdownTime, countdownNum} = blackRemainTime
                let {blackRemainTime: blackMainTime, blackRemainCountdownTime, blackRemainCountdownNum} = this.gameState
                if(mainTime === undefined) mainTime = blackMainTime
                if(countdownTime === undefined) countdownTime = blackRemainCountdownTime
                if(countdownNum === undefined) countdownNum = blackRemainCountdownNum
                return {mainTime, countdownTime, countdownNum}
            },
            whiteRemainTimeCul(){
                let {whiteRemainTime} = this
                let {mainTime, countdownTime, countdownNum} = whiteRemainTime
                let {whiteRemainTime: whiteMainTime, whiteRemainCountdownTime, whiteRemainCountdownNum} = this.gameState
                if(mainTime === undefined) mainTime = whiteMainTime
                if(countdownTime === undefined) countdownTime = whiteRemainCountdownTime
                if(countdownNum === undefined) countdownNum = whiteRemainCountdownNum
                return {mainTime, countdownTime, countdownNum}
            },
            stoneLabelValue(){
                return this.boardStoneLabel == 1
            },
            userWin(){
                let result = this.gameResult
                if(!result) return false
                let color = this.userColor == 1 ? 'B' : 'W'
                return result.indexOf(color) > -1
            },
            invalidGame(){
                if(!this.gameResult) return false
                return this.situation.length < this.PARAMS.game_min_move_num
            },
            isRR(){
                let {gameResult} = this
                return this._common.isRR(gameResult)
            },
            userPlayDisabled(){
                if(this.captureGameOver) return true
                if(!this.isUserPlay || this.gameResult || this.pageDestroy || this.playDisabled) return true
                return false
            },
            isUserPlay(){
                return this.userColor == this.now
            },
            gameId(){
                let data = this.gameInfo
                return data.wsGameId
            },
            isPlaying(){
                return this.gameResult ? false : true
            },
            gameIsEnd(){
                let {gameResult, gameroomInfoCul: {gameroomStatus: status}} = this
                if(gameResult || status == this.ENUMS.ROOM_ENDED || status == this.ENUMS.ROOM_CLOSED || status == this.ENUMS.ROOM_DELETED) return true
                return false
            },
            inviteeUserCode(){
                let {inviteeUserCode} = this.gameroomInfoCul
                return inviteeUserCode
            },
            inviterUserCode(){
                let {inviterUserCode} = this.gameroomInfoCul
                return inviterUserCode
            },
            isReady(){
                if(!this.isCreatedStatus) return true
                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                if(inviteeUserCode == this.usercode || inviterUserCode == this.usercode) return true
                return false
            },
            gameMetaCul(){
                return this.gameMeta || {}
            },
            mainTime(){
                let {gameState: {mainTime} = {}} = this.gameMetaCul
                return mainTime
            },
            countdownTime(){
                let {gameState: {countdownTime} = {}} = this.gameMetaCul
                return countdownTime
            },
            countdownNum(){
                let {gameState: {countdownNum} = {}} = this.gameMetaCul
                return countdownNum || 0
            },
            isOwner(){
                return this.usercode == this.ownerUserCode
            },
            ownerUserCode(){
                let {userCode} = this.gameroomInfoCul
                return userCode
            },
            ownerUserExtendInfo(){
                let {ownerUserCode} = this
                if(ownerUserCode == this.usercode) return this.userInfo
                let {otherUserInfo} = this
                return otherUserInfo
            },
            joinUserExtendInfo(){
                let {ownerUserCode} = this
                if(ownerUserCode == this.usercode) return this.otherUserInfo
                return this.userInfo
            },
            leftInfo(){
                let {blackUserInfo} = this
                if(this.isCreatedStatus) return blackUserInfo
                return this.userColor == 1 ? this.blackUserInfo : this.whiteUserInfo
            },
            rightInfo(){
                let {whiteUserInfo} = this
                if(this.isCreatedStatus) return whiteUserInfo
                return this.userColor == -1 ? this.blackUserInfo : this.whiteUserInfo
            },
            leftRemove(){
                return this.userColor == 1 ? this.blackRemove : this.whiteRemove
            },
            rightRemove(){
                return this.userColor == 1 ? this.whiteRemove : this.blackRemove
            },
            removeTitle(){
                return this.isPlay ? this.TEXT['game-action'].t23 : this.TEXT['game-action'].t3
            },
            blackRemove(){
                let {removeStone} = this
                removeStone = removeStone || {}
                let num = removeStone.w || 0
                return num
            },
            whiteRemove(){
                let {removeStone} = this
                removeStone = removeStone || {}
                let num = removeStone.b || 0
                return num
            },
            gameAction(){
                if(this.gameResult) return false
                return this.isGameStatus
            },
            blackUserInfo(){
                // if(this.isGameStatus) return this.enterGameAction()
                // if(this.isConfigStatus) return this.enterConfigAction()
                // if(this.isCreatedStatus) return this.enterCreatedAction()

                let info = {}
                // let extendInfo = {}
                if(this.isGameStatus) info = this.blackUserInfoGame
                if(this.isConfigStatus) info = this.blackUserInfoGame
                if(this.isCreatedStatus) info = this.blackUserInfoCreate
                // if(info.userCode == this.usercode) extendInfo = this.$store.state.app
                // else extendInfo = this.otherUserInfo
                let opt = {
                    ...info,
                    // ...extendInfo
                }
                opt.usercode = opt.userCode || opt.usercode
                return opt

            },
            whiteUserInfo(){
                let info = {}
                // let extendInfo = {}
                if(this.isGameStatus) info = this.whiteUserInfoGame
                if(this.isConfigStatus) info = this.whiteUserInfoGame
                if(this.isCreatedStatus) info = this.whiteUserInfoCreate
                // if(info.userCode == this.usercode) extendInfo = this.$store.state.app
                // else extendInfo = this.otherUserInfo
                let opt = {
                    ...info,
                    // ...extendInfo
                }
                opt.usercode = opt.userCode || opt.usercode
                return opt
            },
            blackUserInfoGame(){
                let {blackUserCode: value, playerList: list, gameMetaCul, gameroomState} = this
                let {inviterLevel, inviteeLevel} = gameroomState
                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                let level = inviterUserCode == value ? inviterLevel : inviteeUserCode == value ? inviteeLevel : 0
                let key = 'userCode', info = {}
                // let {data} = this._common.selected({list, value, key})
                // data = data || {}
                let data = {
                    nickname: gameMetaCul.blackNickname,
                    // level: gameMetaCul.blackLevel,
                    level,
                    userCode: gameMetaCul.blackUserCode
                }
                if(data.userCode == this.usercode){
                    info = this.userInfo
                }
                else if(data.userCode == this.otherUserInfo.userCode){
                    info = this.otherUserInfo
                }
                let opt = {
                    ...data,
                    ...info
                }
                return opt
            },
            blackUserInfoCreate(){
                let {roomUserList: list, usercode} = this
                let rd = {}
                for(let i in list){
                    if(list[i].userCode != usercode){
                        rd = list[i]
                        break
                    }
                }
                let opt = {
                    ...rd,
                    ...this.ownerUserExtendInfo
                }
                return opt
            },
            whiteUserInfoGame(){
                let {whiteUserCode: value, playerList: list, gameMetaCul, gameroomState} = this
                let {inviterLevel, inviteeLevel} = gameroomState
                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                let level = inviterUserCode == value ? inviterLevel : inviteeUserCode == value ? inviteeLevel : 0
                let key = 'userCode', info = {}
                // let {data} = this._common.selected({list, value, key})
                // data = data || {}
                let data = {
                    nickname: gameMetaCul.whiteNickname,
                    level: gameMetaCul.whiteLevel,
                    userCode: gameMetaCul.whiteUserCode
                }
                if(!data.userCode) return {}
                if(data.userCode == this.usercode) info = this.userInfo
                else if(data.userCode == this.otherUserInfo.userCode) info = this.otherUserInfo
                let opt = {
                    ...data,
                    ...info
                }
                return opt
            },
            whiteUserInfoCreate(){
                let {roomUserList: list, usercode} = this
                let rd = {}
                for(let i in list){
                    if(list[i].userCode != usercode){
                        rd = list[i]
                        break
                    }
                }
                if(!rd.userCode) return {}
                let opt = {
                    ...rd,
                    ...this.joinUserExtendInfo
                }
                return opt
            },
            isGameStatus(){
                return this.gameMeta && !this.gameConfig && !this.isCreatedStatus
            },
            isConfigStatus(){
                return this.gameConfig && !this.gameMeta && !this.isCreatedStatus
            },
            isCreatedStatus(){
                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                if(!inviteeUserCode || !inviterUserCode) return true
                if(!this.gameMeta && !this.gameConfig) return true
                if(inviteeUserCode == this.usercode || inviterUserCode == this.usercode || this.gameMeta) return false
                return true
            },
            gameroomState(){
                let {gameroomStateDto} = this.gameroomInfoCul
                return gameroomStateDto || {}
            },
            gameroomRemainTime(){
                let {gameroomRemainTime} = this.gameroomState
                return gameroomRemainTime || 0
            },
            preGameInfo(){
                let {gameroomInfoCul: info, gameSelected: {resourceDetail: detail}, gameroomState, preGameConfig} = this
                let rd = {
                    roomNum: info.gameroomCode,
                    ...gameroomState,
                    ...detail,
                    ...preGameConfig,
                }
                return rd
            },
            gameRule(){
                let text = this.TEXT['game-action']
                let {intro} = this.gameSelected
                return `${text.t12}：${intro}`
            },
            gameSelected(){
                let {gameList: list, boardSize} = this
                let {gameListSelected: {game_type_text: gameTypeText}} = this
                let rd = {}, detail = {}
                for(let i in list){
                    let {resourceDetail} = list[i]
                    if(resourceDetail.type == gameTypeText && resourceDetail.boardSize == boardSize){
                        rd = list[i]
                        detail = resourceDetail
                        break
                    }
                }  
                let opt = {...rd, ...detail}
                return opt
            },
            optTitle(){
                let {gameSelected: {label = ''}} = this
                return label
            },

            // playTitle(){
            //     let text = this.TEXT['game-action']
            //     let {boardSize} = this
            //     let t1 = `${boardSize}${text['t1']}`
            //     let t2 = text.t19
            //     return `${t1}${t2}`
            // },
            // captureTitle(){
            //     let text = this.TEXT['game-action']
            //     let {boardSize, captureNum} = this
            //     let t1 = `${boardSize}${text['t1']}`
            //     let t2 = `${captureNum}${text['t2']}`
            //     if(this.isPlay){
            //         t2 = text['t19']
            //     }else{
            //        t1 +=  text['t1-1']
            //     }
            //     return `${t1}${t2}`
            // },
            situation(){
                let {gameState, gameInfo} = this
                let {situation} = gameState
                if(!situation) situation = ''
                let tem = situation
                try{
                    situation = situation.split(',')
                }catch(e){ situation = tem }
                if(!this._common.isArray(situation)) situation = []
                let rd = []
                for(let i in situation){
                    if(!situation[i]) continue
                    rd.push(situation[i])
                }
                return rd
            },
            gameState(){
                let meta = this.gameMeta || {}
                return meta.gameState || {}
            },
            gameRemainTime(){
                let {gameState: info} = this
                return this.gameRemainTimeParse(this._common.deepCopy(info))

            },
            boardInitMoves(){
                if(this.isPlay) return []
                let {boardSize} = this
                let moves = this._common.getCaptureInitBoard(boardSize)
                return moves || []
            },
            boardMinNum(){
                let {boardState, boardInitMoves} = this
                if(!boardState) return 0
                return boardInitMoves.length
            },
            gameType(){
                let {gameInfo} = this
                return gameInfo.gameType
            },
            gameListSelected(){
                let {gameType: value} = this
                let {peer_game_info: list} = this.PARAMS
                let key = 'game_type'
                let {data} = this._common.selected({list, value, key})
                return data || {}
            },
            isPlay(){
                return this.gameListSelected.game_type_text == 'play'
            },
            isCapture(){
                return this.gameListSelected.game_type_text == 'capture'
            },
            preGameConfig(){
                let {preGameConfigVo: info} = this.gameroomInfoCul
                return info || {}
            },
            boardSize(){
                let {gameInfo: info} = this
                if(info.boardSize) return info.boardSize
                return this.PARAMS.game_default_board_size
            },
            remainOfflineTime(){
                if(this.isWatcher) return {}
                let {gameState, userColor} = this
                let key = userColor == 1 ? 'black' : userColor == -1 ? 'white' : null
                let time = gameState[`${key}RemainOfflineTime`] || 0
                time = parseInt(time / 1000)
                let minute = parseInt(time / 60)
                let second = time % 60
                return {minute, second}
            },
            userColor(){
                let {blackUserCode, whiteUserCode, usercode} = this
                return blackUserCode == usercode ? 1 : whiteUserCode == usercode ? -1 : null
            },
            gameSgfInfoSame(){
                let {gameroomId: id, gameRound: round} = this.gameSgfInfo || {}
                let {gameroomId, gameRound} = this
                return gameroomId == id && gameRound == round
            },
            blackUserCode(){
                return this.gameInfo.blackUserCode
            },
            whiteUserCode(){
                return this.gameInfo.whiteUserCode
            },
            gameConfig(){
                let info = this.gameroomInfoCul
                return info.gameConfigVo
            },
            gameInfo(){
                let {preGameConfig, gameConfig, gameMeta} = this
                let info = {}
                if(gameMeta && Object.keys(gameMeta).length) info = gameMeta
                if(gameConfig && Object.keys(gameConfig).length) info = gameConfig
                if(preGameConfig && Object.keys(preGameConfig).length) info = preGameConfig
                return info
            },
            rivalUserInfo(){
                let {playerList: list, usercode} = this
                let data = {}
                for(let i in list){
                    let {userCode} = list[i]
                    if(userCode != usercode){
                        data = list[i]
                        break
                    }
                }
                return data
            },
            role(){
                let {usercode, gameroomInfoCul: info} = this
                let {inviteeUserCode, inviterUserCode} = info
                if(usercode == inviteeUserCode || usercode == inviterUserCode) return this.ENUMS.GAME_ROLE_PLAYER
                if(usercode == info.userCode) return this.ENUMS.GAME_ROLE_OWNER
                return this.ENUMS.GAME_ROLE_AUDIENCE

            },
            gameMeta(){
                let info = this.gameroomInfoCul
                return info.gameMetaDto
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            gameResult(){
                let {gameMetaDto: {gameResult} = {}} = this.gameroomInfoCul || {}
                return gameResult
            },
            isWatcher(){
                let {usercode, gameInfo: {blackUserCode, whiteUserCode} = {}} = this
                let {inviteeUserCode, inviterUserCode} = this.gameroomInfoCul
                if(!blackUserCode && !whiteUserCode) return inviteeUserCode != usercode && inviterUserCode != usercode
                return blackUserCode != usercode && whiteUserCode != usercode
            },
            roomIsEnded(){
                return this.isClosed || this.isEnded || this.isDeleted
            },
            isClosed(){
                let {gameroomStatus: status} = this.gameroomInfoCul
                return status >= this.ENUMS.ROOM_CLOSED
            },
            isEnded(){
                let {gameroomStatus: status} = this.gameroomInfoCul
                return this.ENUMS.ROOM_ENDED == status
            },
            isDeleted(){
                let {gameroomStatus: status} = this.gameroomInfoCul
                return this.ENUMS.ROOM_DELETED == status
            },
            gameroomInfoCul(){
                return this.gameroomInfo || {}
            },
            isGameingPath(){
                return this.isGameing
            },
            isGameing(){
                return this.gameMeta ? true : false
            },
            otherUsercode(){
                let {blackUserCode, whiteUserCode} = this.gameInfo
                let rd = {}
                if(!blackUserCode && !whiteUserCode){
                    let {roomUserList: list, usercode} = this
                    for(let i in list){
                        if(list[i].userCode != usercode){
                            rd = list[i]
                            break
                        }
                    }
                }else{
                    if(this.usercode == blackUserCode) rd.userCode = whiteUserCode
                    else if(this.usercode == whiteUserCode) rd.userCode = blackUserCode
                }
                
                return rd.userCode
            },
            gameStatusWatch(){
                if(this.isGameStatus) return 'game'
                if(this.isConfigStatus) return 'config'
                if(this.isCreatedStatus) return 'create'
                return null
            },
            pageInitStateWatch(){
                if(!this.boardState || !this.stompState || this.pageDestroy) return false
                return true
            },
            gameSgfInfoWatch(){
                let {invalidGame, gameResult, gameroomInfo} = this
                return {invalidGame, gameResult, gameroomInfo}
            },
            endTime(){
                let {endTime} = this.gameroomInfo || {}
                return endTime
            },
            // boardInitMovesWatch(){
                // let {isGameing, gameResult, pageHasInit} = this
                // if(!isGameing || gameResult || !pageHas)
            // },
            gameJudgeMaskWatch(){
                let {gameResult, gameJudgeSelected} = this
                return gameResult && gameJudgeSelected
            }
        },
        watch: {
            ...playConfig.watch,
            ...gameJudgeConfig.watch,
            ...gameReviewConfig.watch,
            gameSgfInfoWatch: {
                handler(val, oldVal){
                    if(!val.gameroomInfo || this._common.isSameObject(val, oldVal)) return
                    this.gameSgfInfoSet()
                },
                deep: true,
                immediate: true
            },
            boardInitMoves:{
                handler(val, oldVal){
                    // this.captureBoardInit()
                },
                deep: true,
                immediate: true
            },
            gameJudgeMaskWatch: {
                handler(val, oldVal){
                    if(!val) return
                    this.gameResultCon({active: true})
                },
                deep: true,
                immediate: true
            },
            gameResult: {
                handler(val, oldVal){
                    if(val == oldVal) return
                    if(val){
                        this.timeStop()
                        // this.pageNowFunEmit('localTimeClear')
                        this.gameResultOpen()
                        this.cancelPlay()
                        this.$refs.gameLoading.close()
                    }else this.gameResultClose()
                    if(!val || this.pageStatus) return
                    this.setStatusIdle()
                },
                deep: true,
                immediate: true
            },
            gameStatusWatch: {
                handler(val, oldVal){
                    if(val == oldVal || !val) return
                    if(val != 'create') this.gameStatusChange()
                },
                deep: true,
                immediate: true
            },
            otherUsercode: {
                handler(val, oldVal){
                    if(val == oldVal || !val) return
                    if(this.otherUserInfo.userCode == val) return
                    this.otherUserInfoLoad()
                },
                deep: true,
                immediate: true
            },
            gameroomId: {
                handler(val, oldVal){
                    if(val == oldVal) return
                    
                    if(oldVal) this.gameroomLogout(oldVal)
                    
                    this._userStatus.userStatusQuery()
                },
                deep: true,
                immediate: true
            },
            pageInitStateWatch: {
                handler(val){
                    if(!val) return
                    this.pageInit()
                },
            },
            $route: {
                handler(val, oldVal){
                    this.routeData = this.$route.params.routeData
                    this.pathNow = this.$route.path
                    this.gameroomId = this.$route.params.gameroomId
                    // if(!oldVal) return
                    // let {path: newPath} = val
                    // let {path: oldPath} = oldVal
                    // if(this.isCreated(oldPath) || this.isCreated(newPath)){
                    //     this.gameroomInfoInit(); 
                    //     this.gameroomPlayerInit(); 
                    //     this.gameroomUserInit();
                    //     this._userStatus.userStatusQuery();
                    // }
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageDestroy = true
            this.eventRemove()
            this.unsubscribe()
            this.quitSet()
            this._userStatus.gameTakeCheckByComponent = null
            this._userStatus.gameTakeAction = null
            this._userStatus.userStatusChange = null
            this.gameroomInfoTimeoutEnd()
            this._userStatus.userStatusQuery()
            this.addStompEventRemove()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less" src="../../style/action.less"></style>
<style lang="less">
    .page-com-game-room-index{
        .game-loading{
            z-index: 999;
        }
        .game-result-box{
            position: absolute;
            top: -20px;
            left: 0px;
            width: 100%;
        }


        .game-index-common-box.config{
            .page-title-config{
                position: fixed;
                width: 159px;
                height: 47px;
                left: 0px;
                right: 0px;
                top: 0px;
                margin: auto;
                color: #59428A;
                padding: 15px 0px 0px 33px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/p1.png'));
            }
        }


        // social game style
        .capture-body{
            .options-body{
                
                .opt-item.g-info{
                    .info-item.content{
                        position: relative;
                        .con-item{
                            position: absolute;
                        }
                        .left{
                            width: 110px;
                            height: 100%;
                            .left-bcg{
                                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/game/p1_left.png'));
                                transform: rotate(0);
                            }
                            .com-global-avatar-pet{
                                margin-right: 30px;
                            }
                            .stone{
                                left: 85px;
                                right: auto;
                            }
                        }
                        .right{
                            width: 129px;
                            height: 100%;
                            .right-bcg{
                                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/game/p1_right.png'));
                                transform: rotate(0);
                            }
                            .com-global-avatar-pet{
                                margin-left: 50px;
                            }
                            .stone{
                                left: auto;
                                right: 76px;
                            }
                        }
                        .label-box{
                            position: absolute;
                            width: 33px;
                            height: 100%;
                            top: 0px;
                            left: 85px;
                            overflow: visible;
                            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/game/p2.png'));
                            .body{
                                width: 52px;
                                height: 37px;
                                position: absolute;
                                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/game/p3.png'));
                                left: -11px;
                                top: 39px;
                            }
                        }
                        .con-item{
                            .stone{
                                width: 15px;
                                height: 15px;
                            }
                        } 
                    }
                    
                }
                .opt-item.rule{
                    padding-top: 30px;
                }
                .opt-item.remove-box{
                    margin-top: 3px;
                }
                .opt-item.game-fun{
                    // justify-content: flex-start;
                    margin-top: 4px;
                    .game-fun-body{
                        display: flex;
                        flex-wrap: wrap;
                        width: 185px;
                        margin: 0px auto;
                    }
                    // .fun-item.resign.capture{
                    //     margin-top: 0px;
                    // }
                    // .fun-item+ .fun-item{
                    //     margin-left: 24px;
                    // }
                    // .fun-item.pass{
                    //     margin-left: 0px;
                    // }
                    
                    .fun-item:nth-child(1){
                        margin-left: 0px;
                        margin-top: 0px;
                    }
                    .fun-item:nth-child(2){
                        margin-left: 21px;
                        margin-top: 0px;
                    }
                    .fun-item:nth-child(3){
                        margin-top: 6px;
                        margin-left: 0px;
                    }
                    .fun-item:nth-child(4){
                        margin-left: 21px;
                        margin-top: 6px;
                    }
                    .fun-item:nth-child(5){
                        margin-top: 6px;
                        margin-left: 0px;
                    }
                    .fun-item:nth-child(6){
                        margin-left: 21px;
                        margin-top: 6px;
                    }
                    .fun-item.normal{
                        height: 23px;
                        width: 78px;
                    }
                    .normal-btn-icon.judge{
                        width: 16px;
                        height: 14px;
                    }
                    .com-stone-label-switch{
                        width: 78px;
                        height: 23px;
                        .sw-bar{
                            .sw-button{
                                width: 27px;
                                height: 27px;
                            }
                            .button-shadow{

                                width: 36px;
                                height: 36px;
                            }
                            .button-text.close{
                                font-size: 13px;
                            }
                        }
                    }
                }
                
                .opt-item.game-fun-foot{
                    width: 100%;
                    height: 40px;
                    bottom: 10px;
                    .setting-box{
                        position: absolute;
                        top: 7px;
                        right: 4px;
                        width: 40px;
                        height: 33px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/game/p4.png'));
                        .text{
                            position: absolute;
                            top: 17px;
                            left: 14px;
                            color: #FF7155;
                        }
                    }
                }
            }
        }


    }
    .game-action-style .capture-body .options-body .opt-item.game-fun{
        .fun-item.l{
             margin-right: 0px;
        }
    }
    .room-close-confirm-pro{
        z-index: 9999 !important;
    }
    .social-game-back{
        .gotu-confirm-pro{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
       
</style>