<template>
    <div class="global-com-user-goal-difference-star flex">
        <div class="goal-body stars" :class="[`stars-${stars.length}`, result]">
            <div class="star-item" v-for="v in stars" :key="v" :class="v"></div>
        </div>

    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            result: String,
            goalDifference: [Number, String],
            level: [Number, String],
        },
        data() {
            return {
                // level: 2500,
                // goalDifference: 4
            };
        },
        methods: {},
        created() {},
        components: {},
        computed: {
            stars(){
                let {level} = this
                let levelData = this._common.getLevel(level)
                let {goalDifference} = levelData
                let data = []
                for(let i = 0; i < goalDifference; i++){
                    let className = 'gov'
                    let k = Number(i) + 1
                    if(this.goalDifference >= k) className = 'st'
                    className = `${className} item-${k}`
                    data.push(className)
                }
                return data
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .global-com-user-goal-difference-star{
        .goal-body{
            margin: auto;
            position: relative;
            display: flex;
            
        }
    }
</style>
<style lang="less" src="../less/star3.less"></style>
<style lang="less" src="../less/star4.less"></style>
<style lang="less" src="../less/star5.less"></style>