<template>
    <div class="com-social-game-game-result flex-center" v-if="active">
        <div class="re-body">
            <div class="re-title-box">
                <span class="text-normal-aa bold flex-center">{{resultTitle}}</span>
            </div>
            <div class="win-ext-bcg" v-if="userWin"></div>
            <div class="re-body-box">
                <div class="body-item result" :class="bodyClass">
                    <div class="win-body"></div>
                    <!-- <div class="result-text-invalid">
                        <span class="text-normal bold">无效对局</span>
                    </div> -->
                    <div class="result-stars" :class="`stars-${stars}`">
                        <stars :level="userLevel" :goalDifference="goalDifference" :result="bodyClass"></stars>
                    </div>
                    <div class="user-level-box flex-center">
                        <user-level class="flex-center bold"></user-level>
                    </div>
                    <div class="c-gift-box position-align-center-margin" v-if="gift">
                        <div class="gift-item icon"></div>
                        <div class="gift-item text">
                            <span class="text-normal-c bold">+{{gift}}</span>
                        </div>
                    </div>
                </div>
                <div class="body-item fun flex">
                    <div class="fun-item" v-for="v in funListCul" :key="v.fun" :class="[v.class]">
                        <sl-button type="confirm" @click="funHand(v)" :disabled="v.isDisabled" :loading="loading == v.fun">
                            <span class="text-normal-aa">{{v.name}}</span>
                            <span class="text-normal-aa" v-if="v.extendText && !v.isDisabled && countdownVal">{{v.extendText}}</span>
                        </sl-button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
    import appApiClass from '@/API/page/app'
    import stars from './stars.vue'

    export default {
        name: '',
        props: {
            isTaste: Boolean,
            result: String,
            gameType: String,
            userColor: [Number, String],
            oldLevel: [Number, String],
            invalidGame: Boolean,
            usercode: [Number, String],
            gameMeta: {
                type: Object,
                default(){ return {} }
            },
            loading: String,
            gameId: [String, Number],
            gift: [String, Number],
        },
        data() {
            return {
                // gift: 2,
                appData: new this.appDataClass({key: 'app'}),
                appApi: new appApiClass(),
                active: false,
                // stars: [

                // ],
                funList: [
                    {fun: 'review', class: 'review', name: '复盘', disabledCul: 'reviewDisabled'},
                    {fun: 'playAgain', class: 'play-again', name: '申请续战', extendTextCul: 'playAgainCountdown', disabledCul: 'playAgainDisabled'},
                    {fun: 'tasteAgain', class: 'taste-again', name: '重新匹配', display: 'tasteDisplay', disabledCul: 'tasteDisabled'},
                    {fun: 'quit', class: 'quit', name: '退出', disabledCul: 'quitDisabled'},
                ],
                userLevel: 0,
                goalDifference: 0,
                countdownVal: null,
                playAgainRemainTime: 0
            };
        },
        methods: {
            playAgain(){
                if(this.playAgainDisabled) return
                this.countdownStop()
                this.$emit('emitEvent', {
                    fun: 'playAgain'
                })
            },
            funHand(val){
                val = val || {}
                let {fun} = val
                if(!this[fun]) return
                this[fun](val)
            },
            quit(){
                // this.$emit('emitEvent', {
                //     fun: 'gameBack'
                // })
                this.close()
            },
            async init(){
                await this.userLevelInit()
            },
            async open(){
                await this.init()
                this.active = true
                this.countdownStart()
            },
            async userLevelInit(){
                let res = await this.appApi.userLevel()
                if(!res) return
                // this.userLevel = this.appData.getStoreItemByCom('userLevel')
                // this.goalDifference = this.appData.getStoreItemByCom('goalDifference')
                this.userLevel = res.level
                this.goalDifference = res.goalDifference
            },
            close(){
                this.active = false
            },
            countdownStart(){
                this.countdownStop()
                // if(this.playAgainDisabled) return
                this.playAgainRemainTime = this.PARAMS.play_again_countdown_time
                let t = 1000
                this.countdownVal = setInterval(() => {
                    if(this.playAgainRemainTime <= 0) return this.countdownStop()
                    this.playAgainRemainTime -= t
                }, t);
            },
            countdownStop(){
                if(!this.countdownVal) return
                clearInterval(this.countdownVal)
                this.countdownVal = null
            },
            review(){
                this.$emit('emitEvent', {
                    fun: 'gameReview'
                })
            },
            tasteAgain(){
                this.$emit('emitEvent', {
                    fun: 'tasteAgain'
                })
            },
        },
        created() {},
        components: {
            stars
        },
        computed: {
            gameroomInfo(){
                let {gameroomInfo = {}} = this.gameMeta || {}
                return gameroomInfo || {}
            },
            playAgainMaxNum(){
                let maxNum = this.PARAMS.play_again_max_num
                let list = this.currentInviters
                let {usercode} = this, num = 0
                for(let i in list){
                    if(list[i] == usercode) num++
                    if(num >= maxNum) break
                }
                return num >= maxNum
            },
            currentInviters(){
                let {
                    gameroomInfo: {
                        gameroomStateDto: {currentInviters: list = ''} = {}
                    } = {}
                } = this
                try{
                    list = list.split(',')
                }catch(e){ list = [] }
                if(!this._common.isArray(list)) return []
                let data = []
                for(let i in list){
                    if(!list[i] || list[i] == '' || list[i] == ' ') continue
                    data.push(list[i])
                }
                return data
            },
            resultTitle(){
                let k = this.userWin ? 'win' : this.isRR ? 'draw' : this.isNR ? 'none' : 'lose'
                let key = `social game result title ${k}`
                return this._errorCode.game[key]
            },
            reviewDisabled(){
                return this.loading ? true : false
            },
            tasteDisabled(){
                return this.loading ? true : false
            },
            quitDisabled(){
                return this.loading ? true : false
            },
            playAgainDisabled(){
                if(this.playAgainMaxNum) return true
                let {playAgainRemainTimeCul: time} = this
                if(time <= 0) return true
                if(this.userIsQuit) return true
                return false
            },
            userIsQuit(){
                let {gameMeta: {gameroomInfo: info, userList}} = this
                info = info || {}
                let {gameroomStateDto: state = {}} = info || {}
                if(state.onlineUserCount < 2) return true
                let player = {}
                player[info.inviterUserCode] = true
                player[info.inviteeUserCode] = true
                for(let i in userList){
                    let {userCode} = userList[i]
                    delete player[userCode]
                }
                if(Object.keys(player).length) return true
                return false
            },
            playAgainCountdown(){
                let {playAgainRemainTimeCul: time} = this
                if(this.userIsQuit) return null
                return `(${time}s)`
            },
            playAgainRemainTimeCul(){
                let {playAgainRemainTime: time} = this
                time = parseInt(time / 1000)
                return time
            },
            stars(){
                let {userLevel} = this
                let levelData = this._common.getLevel(userLevel)
                let {goalDifference} = levelData
                return goalDifference || 0
            },
            bodyClass(){
                let {userWin, isRR, isNR} = this
                return userWin ? 'win' : isRR ? 'draw' : isNR ? 'none' : 'lose'
                // return 'draw'
            },
            tasteDisplay(){
                return this.isTaste
            },
            funListCul(){
                let {funList: list} = this
                return this._common.funhandAct.call(this, list)
            },
            isRR(){
                let {result} = this
                return this._common.isRR(result)
            },
            isNR(){
                let {result} = this
                return this._common.isNR(result)
            },
            winColor(){
                if(this.invalidGame || this.isRR) return 0
                let win = this._common.resultWinLabel(this.result)
                return win == 'b' ? 1 : win == 'w' ? -1 : 0
            },
            userWin(){
                if(!this.winColor) return false
                return this.winColor == this.userColor
            },
            userLose(){
                if(!this.winColor) return false
                return this.winColor != this.userColor
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.countdownStop()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-social-game-game-result{
        top: 0px;
        left: 0px;
        z-index: 500;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        .re-body{
            width: 243px;
            height: 316px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            justify-content: center;
            .re-title-box{
                width: 100%;
                position: absolute;
                left: 0px;
                top: 12px;
                text-align: center;
                z-index: 3;
            }
            .win-ext-bcg{
                position: absolute;
                z-index: 1;
                width: 465px;
                height: 342px;
                left: -107px;
                top: -31px;
                // top: 0px;
                // left: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/result/win/p1.png'));
            }
            .re-body-box{
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                margin: auto;
                z-index: 2;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            .result.win{
                .win-body{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/result/win.png'));
                }
                
            }
            .result.lose{
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/result/lose.png'));
            }
            .result.draw{
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/room/result/draw.png'));
            }
            .result-text-invalid{
                position: absolute;
                top: 20px;
                width: 100%;
                text-align: center;
            }
            .body-item.result{
                width: 243px;
                height: 255px;
                margin: 0px auto;
                position: relative;
                // .stars-3{
                //     width: 110px;
                // }
                // .stars-4{
                //     width: 135px;
                // }
                // .stars-5{
                //     width: 108px;
                // }
                .result-stars{
                    left: 0px;
                    right: 0px;
                    margin: auto;
                    position: absolute;
                    width: 100%;
                    top: 132px;
                    z-index: 2;
                }
                .user-level-box{
                    position: absolute;
                    width: 100%;
                    bottom: 30px;
                    color: #fff;
                    z-index: 2;
                    .global-com-user-level > span{
                        font-size: 18px;
                        height: auto;
                        line-height: normal;
                    }
                }  
                
                .c-gift-box{
                    z-index: 2;
                    padding-right: 12px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    bottom: 0px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/g37.png'));
                    width: 66px;
                    height: 22px;
                    .gift-item.text{
                    }
                    .gift-item.icon{
                        position: absolute;
                        width: 29px;
                        height: 29px;
                        bottom: 0px;
                        left: 0px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g38.png'));
                    }
                }
            }
            .fun{
                margin-top: 15px;
                justify-content: space-between;
                .com-global-sl-button{
                    color: #A2642A;
                    .button-shadow{
                        .background-img-max(url('@{assetsUrlV2_0}/global/btn_shadow.png'));
                    }
                }
                .fun-item+ .fun-item{
                    margin-left: 18px;
                }
                .fun-item{
                    .com-global-sl-button{
                        height: 40px;
                    }
                }
                .fun-item.review{
                    .com-global-sl-button{
                        width: 73px;
                    }
                }
                .fun-item.play-again{
                    .com-global-sl-button{
                        width: 110px;
                    }
                }
                .fun-item.taste-again{
                    .com-global-sl-button{
                        width: 93px;
                    }
                }
                .fun-item.quit{
                    .com-global-sl-button{
                        width: 73px;
                    }
                }
            }

            
        }
    }
</style>