import apiClass from '@/API/page/game'


    export default {
        name: '',
        props: {
        },
        data() {
            return {
                judgeApi: new apiClass(),
                judgeMaskState: false,
                gameJudge: {
                    active: false,
                    data: {},
                },
                gameJudgeResult: {},
                endGameData: {
                    active: false
                },
            };
        },
        methods: {
            gameJudgeCon(){
                if(this.gameJudgeSelected) return this.gameJudgeOff()
                if(!this.gameJudgeCheck()) return
                this.gameJudgeOn()
            },
            async gameJudgeOn(judgeData){
                if(!this._board) return
                let level = this.AILevel
                let {boardSize, komi} = this
                let moves = this._board.getSituation()
                let res = judgeData || await this.judgeApi.judge({level, boardSize, moves, komi})
                if(!res) return
                this.gameJudgeHand(res)
            },
            async gameJudgeHand(res){
                // this.timeStop()
                // res = res || {}
                // this.judge.active = true
                // this.judge.data = this._common.deepCopy(res)
                // let data = res.belong
                // let result = this._board.gameJudgeOn(data)



                res = res || {}
                let orignalData = this._common.deepCopy(res)
                let data = res.belong
                this.gameJudge.active = true
                let result = this._board.gameJudgeOn(data)
                result.orignalData = orignalData
                result.winner = res.winner
                result.delta = res.delta
                this.gameJudgeResult = result
                let state = await this.resultCheck(result)
                if(!state) return
                this.gameJudgeResult.resultText = state
                this.setEndGameData({data: orignalData})
                // this.endGameJudgeCon()
                return state
            },
            
            async resultCheck(res){
                let result = this.getGameJudgeResult(res)
                if(!result) return
                await this.gameResultSet(result)
                return result
            },
            getGameJudgeResult(res){
                if(res.winner == 'U') return false
                let {winner, delta} = res 
                let gameResult = `${winner}+${delta}`
                return gameResult
            },
            gameJudgeOff(){
                this.gameJudge.active = false
                this.gameJudge.data = {}
                this._board.gameJudgeOffPlay()
                this.gameResultCon({active: false})
            },
            gameJudgeCheck(){
                if(!this.judgeDisabled) return true
                let message = this.judgeDisabled
                this._common.toastOpen({
                    message
                })
                return false
                // return true
            },
            gameResultCon(val){
                val = val || {}
                let {active} = val
                this.judgeMaskState = active
            },
        
            endGameJudgeCon(){
                let {endGameData: {active}} = this
                return active ? this.endGameJudgeOff() : this.endGameJudgeOn()
            },
            endGameJudgeOn(){
                let {endGameData: {type}} = this
                let fun = `endGame_${type}_on`
                if(!this[fun]) return
                this.boardChartCheck('endGameJudge')
                this.endGameData.active = true
                this[fun]()
            },
            endGameJudgeOff(){
                let {endGameData: {type, active}} = this
                let fun = `endGame_${type}_off`
                if(!this[fun]) return
                this.endGameData.active = false
                this[fun]()
            },
            setEndGameData(opt){
                opt = opt || {}
                this.endGameData = {
                    position: this.position,
                    type: 'judge',
                    gameId: this.gameId,
                    active: false,
                    ...opt
                }
            },
            endGame_judge_on(){
                let {endGameData: {data}} = this
                let judgeData = data.belong
                
                let result = this._board.gameJudgeOn(judgeData)
                result.winner = data.winner
                result.delta = data.delta
                result.resultText = this.gameResult
                this.gameJudgeResult = result
            },
            endGame_judge_off(){
                this._board.gameJudgeOffPlay()
                this.$set(this.gameJudgeResult, 'winner', null)
                this.gameJudgeResult = {}
            },
        
        },
        created() {},
        components: {},
        computed: {
            judgeMaskDisplay(){
                if(this.judgeMaskState && !this.gameResult) return true
                return false
            },
            judgeDisabled(){
                let {boardSize, positionCul} = this
                let list = this._common.gameJudgeMoveNum
                let num = list[boardSize] || 0
                if(positionCul >= num) return false
                let message = this.TEXT.game.judgeDis
                message = this._common.urlParse(message, {num})
                return message
            },
            gameJudgeSelected(){
                return this.gameJudge.active
            },
        },
        watch: {
            gameJudge: {
                handler(val, oldval){
                    // if(this.gameResult) return
                    // this.gameResultCon(val)
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }