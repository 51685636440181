    import historyClass from "@/API/page/history";
    
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                historyApi: new historyClass()
            };
        },
        methods: {
            async gotoGameReview(){
                await this.gotoReportBefore()
                let sgfId = await this.reportIdInit()
                this.gotoReportAction(sgfId)
            },
            async gotoReportAction(gameId){
                if(!gameId) return this.messageNotice(this._errorCode.game['none game id for report'])
                // if(!this.hasReportData){
                //     let state = await this.getGameReportCount(gameId)
                //     if(!state) return
                // }
                this.toReview(gameId)
            },
            async getGameReportCount(val){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                }
                let res = await this.historyApi.getGameReportCount(opt)
                if(!res) return false
                let {dailyRemainCount: count} = res
                if(count >= 1) return true
                let result = await this.createReportConfirmNotice()
                if(result != 0) return false
                res =  await this.changeUserGameReport()
                if(!res) return
                return true
            },
            toReview(val){
                this._routeHand.goto({
                    name: 'game-review-summary',
                    type: 'replace',
                    params: {gameId: val}, 
                })
            },
            async reportIdInit(){
                if(this.hasReportData) return this.hasReportData.id
                await this.gameIdInitForReport()
                return this.sgfMeta.id
            },
            async gameIdInitForReport(){
                if(this.sgfId && this.sgfMeta.gameroomId == this.gameroomId && this.isOwnSgf) return true
                
                if(this.gameSgfInfo.gameroomId != this.gameroomId) await this.gameSgfInfoSet()
                await this._common.nextTick.call(this)
                if(!this.isOwnSgf) await this.copyGame()
                await this._common.nextTick.call(this)
            },
            async gotoReportBefore(){
                if(this.hasReportData && this.hasReportData.userCode == this.usercode) return true
                await this.gameInfoUpdate()
                return true
            },
            async gameInfoUpdate(opt){
                let {callback} = opt || {}
                await this.gameSgfInfoSetAction()
                if(callback) callback()
            },
            async createReportConfirmNotice(){
                let res = await this._common.confirmPro.open({
                    message:'今天的复盘次数已经超过上限(3次),',
                    subMessage:`是否确认消耗30棋石复盘此局？`,
                    confirmType:'c6',
                    buttons: [
                        {title: '取消', label: 1, type: 'cancel'},
                        {title: '确定', label: 0, type: 'confirm'},
                    ]
                })
                res = res || {}
                return res.label
            },
            async changeUserGameReport(){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                }
                let res = await this.historyApi.changeUserGameReport(opt)
                return res
            },
        },
        created() {},
        components: {},
        computed: {
            hasReportData(){
                if(this.isOwnSgf){
                    let {analyzeStatus} = this.sgfMeta || {}
                    if(analyzeStatus == this.ENUMS.REPORT_STATUS) return this.sgfMeta
                }
                return this.publicReport || false
            },
            isOwnSgf(){
                let {sgfMeta} = this
                sgfMeta = sgfMeta || {}
                return sgfMeta.userCode == this.usercode
            },
            publicReport(){
                let {content: list = [], gameroomId} = this.gameSgfInfo || {}
                let rd = null
                for(let i in list){
                    let {analyzeStatus, analyzeShare} = list[i]
                    if(analyzeStatus == this.ENUMS.REPORT_STATUS && analyzeShare == this.ENUMS.ANALYZE_SHARE){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
    