import gameClass from '@/API/page/game'


export default {
    name: '',
    props: {
    },
    data() {
        return {
            gameApi: new gameClass(),
            blackRemainTime: {},
            whiteRemainTime: {},
            gamePlayCoor: {},
            funAfter: {
                'play': ['computerPlayCheck'],
                'undo': ['computerPlayCheck'],
                'loadSgf': ['computerPlayCheck'],
                'changeBoard': ['computerPlayCheck'],
                'pass': ['computerPlayCheck']
            },
            tryPlay: false,
            boardTryPlayData: {},
            gameResultSetLoading: false,
            blackReadTime: false,
            whiteReadTime: false,
            tryPlayData: {
                newest: false
            },
            applyJudge: {
                active: false,
                winrate: null,
                delta: null,
                result: null,
                sender: null,
                orignalData: null,
            },
            applyJudgeUserState: {},
            area:{
                active: false,
                loading: false,
                data: {},
                color: null
            },
            nonePlayWatch: false,
            boardChatFun: {
                'area': {
                    off: ['gameJudge', 'prop', 'endGameJudge'],
                    clear: ['prop']
                },
                'branch': {
                    off: ['area', 'gameJudge', 'prop', 'endGameJudge'],
                    clear: ['prop']
                },
                'prop': {
                    off: ['area', 'branch', 'gameJudge', 'endGameJudge'],
                    clear: []
                },
                'endGameJudge': {
                    off: ['area', 'branch', 'prop', 'gameJudge'],
                    clear: []
                },
                'gameJudge': {
                    off: ['area', 'branch', 'prop', 'endGameJudge'],
                    clear: ['prop']
                }
            },
            computerPlayResponse: null,
            genmoveLoading: false,
            gameReadyWatchState: false,
            boardHandResponse: null
        };
    },
    methods: {
        async gameSubscribe(){
            let {gameId} = this
            if(!gameId) return
            return new Promise(success => {
                this._eventBus.$emit('gameLinkTakeFun', {
                    fun: 'gameSubscribe',
                    data: {
                        gameId,
                        callback: this.messageHand,
                        promiseFun: res => { 
                            success()
                        }
                    }
                })
            })
        },
        gameSubscribeSuccess(res){
            this.timeInit()
            if(this.sendByOwn(res)) this.gameSubscribeState = true
            if(!this.sendByOwn(res)) return
            this.gameReadyCheck()
        },
        async gameReadyCheck(opt){
            if(this.gameResult) return
            if(this.gamePlaying) return this.playGameStart(opt)
            if(!this.gameIsReady) await this.readySend(opt)
            else console.log('user ready and wait...')
        },
        gameReadyIntervalCon(val){
            if(this.isWatcher) return
            if(!val) return this.gameReadyIntervalOff()
            if(this.gameReadyWatchState) return
            this.gameReadyWatchState = true
            if(this.$refs.gameLoading) this.$refs.gameLoading.open({
                message: this._errorCode.game['game ready check']
            })
            this.gameReadyIntervalOn()
        },
        async gameReadyIntervalOn(t){
            t = t || 0
            if(!this.gameReadyWatchState || !this.gameReadyWatch) return 
            await this.gameroomInfoInit({noLoad: true})
            // if(!this.gameReadyIntervalCheck(t)) return
            await this._common.nextTick.call(this)
            await this.gameReadyCheck({noLoad: true})
            let delayTime = 3000
            await this._common.settimeout(delayTime)
            t += delayTime
            this.gameReadyIntervalOn(t)
        },
        gameReadyIntervalOff(){
            if(this.$refs.gameLoading) this.$refs.gameLoading.close()
            this.gameReadyWatchState = false
        },

        gameUnsubscribe(){
            this.gameSubscribeState = false
            let fun = 'gameUnsubscribe'
            this._eventBus.$emit('gameLinkTakeFun', {
                fun,
            })
        },
        async readySend(opt){
            if(this.gameResult || this.isWatcher) return
            let {gameId: game_id} = this
            opt = opt || {}
            let res = await this.api.gameReady({...opt, game_id})
            return true
        },
        async playGameStart(){
            if(this.gameResult) return
            await this._common.nextTick.call(this)
            // this.timeInit()
            await this.timeUpdateByGameState(this.gameState)
            this.captureBoardInit()
            this.timeStartCon('continue')
            this.nonePlayWatch = true
        },
        async timeUpdateByGameState(data, opt){
            
            let {type, sendByOwn} = opt || {}
            if(this.gameResult) return
            let blackData = {
                mainTime: data.blackRemainTime,
                countdownTime: data.blackRemainCountdownTime,
                countdownNum: data.blackRemainCountdownNum,
            }
            let whiteData = {
                mainTime: data.whiteRemainTime,
                countdownTime: data.whiteRemainCountdownTime,
                countdownNum: data.whiteRemainCountdownNum,
            }
            
            if(this.isWatcher){
                if(this.$refs.blackTime) this.$refs[`blackTime`].timeUpdateByGameState(blackData)
                if(this.$refs.whiteTime) this.$refs[`whiteTime`].timeUpdateByGameState(whiteData)
            }else{
                let color = this.blackUserCode == this.usercode ? 'black' : 'white'
                let colorData = color == 'black' ? blackData : whiteData
                let otColor = color == 'black' ? 'white' : 'black'
                let otColorData = color == 'black' ? whiteData : blackData
                let f = true
                if(type == 'genmove'){
                    // if(sendByOwn) otColorData.countdownTime = this.gameState.countdownTime
                    this.timeStop()
                }
                this.$refs[`${otColor}Time`].timeUpdateByGameState(otColorData)
                this.$refs[`${color}Time`].timeUpdateByGameState(colorData)
            }
            await this._common.nextTick.call(this)

        },

        async timeStartCon(type){


            if(!this.timeInitCheck) return
            if(this.gameResult) return
            this.timePause()
            let color = this.colorNow == 1 ? 'black' : 'white'
            if(this[`${color}Disconnect`]) return
            // if(type == 'step') await this.timeReset(color)
            // this.timeStart(color)
            let ele = this.$refs[`${color}Time`]
            if(!ele || !ele.start) return
            ele.start(type)
        },
        timeInitCheck(){
            let {blackRemainTime: black, whiteRemainTime: white} = this
            let f = true
            for(let i in black){
                if(black[i] === null){
                    f = false
                    break
                }
            }
            if(!f) return f
            for(let i in white){
                if(black[i] === null){
                    f = false
                    break
                }
            }
            return f
        },
        timeStartBefore(){
            let color = this.colorNow == 1 ? 'black' : this.colorNow == -1 ? 'white' : null
            if(!color) return
            let timeData = this[`${color}RemainTime`] || {}
            let {mainTime, countdownTime, countdownNum} = timeData
            if(mainTime <= 0){
                if(countdownNum <= 1 && countdownTime <= 0){
                    return
                }
                if(!this[`${color}ReadTime`] && countdownNum == this.gameState.countdownNum && countdownTime == this.gameState.countdownTime){
                    
                    if(!this.readTimeStartDisabled){
                        this[`${color}ReadTime`] = true
                        this.audioPlay('game start word')
                    }
                    if(this.needSyncTimeByNoneMainTime) this.gameTimeSync()
                }
            }
        },
        timeStop(){
            if(this.$refs['blackTime']) this.$refs['blackTime'].stop()
            if(this.$refs['whiteTime']) this.$refs['whiteTime'].stop()
        },
        timeLocalInit(){
            let blackTime = this.appData.getItem('blackTime') || {}
            let whiteTime = this.appData.getItem('whiteTime') || {}
            this.blackTimeData = blackTime
            this.whiteTimeData = whiteTime
            if(this.$refs['blackTime']){
                let {mainTime = this.mainTime, countdownTime = this.countdownTime, countdownNum = this.countdownNum} = blackTime
                this.$refs['blackTime'].setValue({mainTime, countdownTime, countdownNum})
            }

            if(this.$refs['whiteTime']){
                let {mainTime = this.mainTime, countdownTime = this.countdownTime, countdownNum = this.countdownNum} = whiteTime
                this.$refs['whiteTime'].setValue({mainTime, countdownTime, countdownNum})
            }
        },
        endGameSet(res){
            let {sender} = res || {}
            this.endGameSetUser = sender
        },
        localTimeClear(){
            this.appData.removeItem({
                blackTime: true,
                whiteTime: true,
                gameId: true
            })
        },
        playByclickEmit(opt){
            let {x, y} = opt || {}
            delete opt.x
            delete opt.y
            this.playByclick(x, y, {...opt})
        },
        playByclick(x, y, opt){
            if(this.userPlayDisabled) return
            this._board.playByGame(x, y, opt)
            if(!this.confirmPlaySwitch) this.confirmPlay()
        },
        confirmPlay(){
            if(!this._board) return
            if(this.userPlayDisabled) return this.cancelPlay()
            this._board.confirmPlay()
            this.funAfterAction('pass')
        },
        async funAfterAction(name){
            if(!this.funAfter[name]) return
            await this._common.nextTick.call(this)
            let fun = this.funAfter[name]
            for(var i in fun){
                if(this[fun[i]]) this[fun[i]]()
            }
            this.closeAllProbs()
        },
        update(opt){
            this._common.update.call(this, opt)
        },

        async computerPlayCheck(){
            let res = await this.genmove()
            if(this.computerPlayResponse) this.computerPlayResponse()
            this.computerPlayResponse = null
        },

        async genmove(num){
            if(this.gameResult || this.isWatcher){
                this.genmoveLoading = false
                return
            }
            num = num || 1
            let moves = this.getSituation()
            let {gameId} = this
            let params = this.genmoveParams
            params.noLoad = true
            params.noError = true
            // if(this.now == -1 && this.positionNowCul == 1) params.situation = '-1,59,-1,288,72,300,186,174,66,294,263,180,317,298,297,318,279,299,336,337,280,355,335,354,353,356,315,296,277,295,281,314,333,332,278,301,276,275,306,307,287,268,267,249,248,230,326,327,325,346,308,309,289,269,290,310,291,311,292,293,254,273,272,274,252,54,73,53,52,33,51,32,50,31,235,49,68,48,67,47,65,46,61,42,43,23,62,45,64,25,24,5,44,60,99,98,137,136,158,177,159,178,142,143,124,144,125,145,126,146,127,147,129,161,141,160,148,166,185,184,203,183,202,182,201,200,219,218,256,217,237,216,215,196,214,193,41,22,195,175,176,157,138,156,121,117,118,80,81,79,120,213,232,231,194,212,251,229,286,228,247,345,344,328,255,351,316,352,334,283,264,284,265,312,282,302,198,197,55,35,36,17,37,16,74,128,109,167,168,149,169,110,130,108,90,89,70,270,30,11,29,28,40,39,21,2,26,6,7,4'
            this.genmoveLoading = true
            let res = await this.api.genmove(params)
            if(!res){
                num++
                this.genmoveErrorHand(num)
                await this._common.settimeout(1000)
                return this.genmove(num)
            }
            this.genmoveErrorClose()
            this.roomInfoUpdate()
            this.genmoveLoading = false
        },
        genmoveErrorClose(){
            this.messageNoticeClose()
        },
        genmoveErrorHand(num){
            let list = {
                '1': this._errorCode.game['genmove error 1'],
                '3': this._errorCode.game['genmove error 3'],
            }
            let maxNum = 3
            let n = parseInt(num / maxNum)
            let message = list[n]
            if(!message) return
            // if(!this.stompStatus) return
            this.messageNotice(message)
        },
        async boardHand(){
            this.boardHandSwitch = true
            await this._common.nextTick.call(this)
            let moves = this.situation
            let num = moves.length
            if(num === null || isNaN(Number(num))){
                this.boardHandSwitch = false
                this.boardHandCallback()
                return
            }
            let {stepNowCul: stepOld} = this
            let sitOld = this.getSituation(stepOld)
            let movesNew = moves
            this.syncBoard(movesNew)
            await this._common.nextTick.call(this)
            let {stepNowCul: stepNew} = this
            let sitNew = this.getSituation(stepNew)
            this.playVoiceHandl(sitOld, sitNew)
            this.boardHandSwitch = false
            this.boardHandCallback()
            return sitNew == sitOld
        },
        boardHandCallback(){
            if(!this.boardHandResponse) return
            this.boardHandResponse()
            this.boardHandResponse = null
        },
        boardSyncWatch(){
            return new Promise(res => {
                if(!this.boardHandSwitch) return res()
                this.boardHandResponse = res
            })
        },
        getSituation(p, params){
            if(!this._board) return ''
            params = params || {}
            let fun = 'getSituation'
            if(params.syncBoard) fun = 'getSituationSync'
            return this._board[fun](p)
        },
        syncBoard(moves){
            if(this.applyJudgeSelected) this.applyJudgeOff()
            let pOld = this.position
            let movesNow = this.getSituation(this.stepNowCul, {syncBoard: true})
            movesNow = movesNow == '' ? [] : movesNow.split(',')
            let movesNew = this._common.deepCopy(moves)
            if((!movesNow || movesNow == '' || movesNow.length <= 0) && !this.tryPlaySelected){
                this.loadMovesToBoard(movesNew)
            }else{
                let backNum = 0, playMoves = []
                if(movesNow.join(',') == movesNew.join(',')) return
                
                if(movesNow.length > movesNew.length){
                    backNum = movesNow.length - movesNew.length
                    movesNow.splice(movesNew.length, movesNow.length - movesNew.length)
                }else{
                    playMoves = movesNew.splice(movesNow.length, movesNew.length - movesNow.length)
                }
                let deltaMoves = this.getMovesDelta(movesNow, movesNew)
                deltaMoves.backNum = Number(deltaMoves.backNum) + backNum
                deltaMoves.moves = playMoves
                playMoves = deltaMoves.backMoves.concat(playMoves)
                // 
                if(deltaMoves.backNum && deltaMoves.backNum > 0){
                    this.backSync(deltaMoves.backNum)
                }
                for(var i in playMoves){
                    let coor = this._board.board.coor1to2(playMoves[i])
                    this.playByclickSync(coor.x, coor.y)
                }
            }
        },
        async playByclickSync(x, y){
            let noWatchProbs = true
            let watch = false
            if(this.isWatcher){
                noWatchProbs = undefined
                watch = true
            }
            // if(this.probLoading) noWatchProbs = true
            this._board.playBySync(x, y, {noWatchProbs, watch, probLoading: this.probLoading})
            await this._common.nextTick.call(this)
            if(x == -1 && y == -1 && this.isUserPlay){
                this.$toast({
                    message: this._errorCode.game['opponent passed']
                })
            }
        },
        backSync(num){
            let noWatchProbs = true
            if(this.isWatcher){
                noWatchProbs = undefined
            }
            this._board.backSync(num, {noWatchProbs})
        },
        getMovesDelta(m1, m2){
            let opt = {
                backNum: 0,
                backMoves: []
            }
            if(m1.join(',') == m2.join(',')) return opt
            for(var i in m1){
                if(m1[i] == m2[i]) continue
                opt.backNum = m1.length - i
                opt.backMoves = m2.splice(i, opt.backNum)
                break
            }
            return opt
        },
        applyJudgeOff(){
            this.applyJudge.active = false
            this._board.areaOff()
        },
        loadMovesToBoard(moves){
            if(!moves || moves.length <= 0) return
            this._board.loadSgf(moves)
        },
        playVoiceHandl(sitOld, sitNew){
            sitOld = sitOld || ''
            sitNew = sitNew || ''
            let lengthOld = sitOld.length
            let lengthNew = sitNew.length
            if(lengthOld == lengthNew || lengthNew < lengthOld){
                return
            }
            this._voiceHand.normalPlay('stone')
        },
        timeout(color){
            if(this.isWatcher) return
            let {userColor} = this
            userColor = userColor == 1 ? 'black' : 'white'
            if(userColor != color) return
            this.timeoutSet(color)
        },
        async timeoutSet(color){
            if(this.gameResult) return
            let result = color == 'black' ? 'W' : 'B'
            result += '+T'
            this._voiceHand.normalPlay('game time out')
            let res = await this.gameResultSet(result)
        },
        async gameResultSet(result){
            if(!result || this.gameResult || !this.pageHasInit || this.gameResultSetLoading || !this.gamePlaying){
                return
            }
            this.gameResultSetLoading = true
            let {gameId: game_id} = this
            let game_result = this.gameResultRepair(result)
            let res = await this.api.endGame({game_id, game_result})
            this.gameResultSetLoading = false
            return res
        },
        gameResultRepair(result){
            // if(this.stepNowCul <= this.PARAMS.game_min_move_num) return this.PARAMS.invalid_game_key
            return result
        },
        async handicapInit(){
            await this._common.nextTick.call(this)
            let {situation, handicap, userColor} = this
            if(situation && situation.length) return
            handicap = Number(handicap)
            if(!handicap || isNaN(handicap)) return
            if(userColor != 1) return
            this.setHandicap(handicap)
            await this._common.nextTick.call(this)
            this.genmove()
        },
        captureBoardInit(){
            let {userColor} = this
            if(this.isPlay || this.isWatcher || this.step || this.situation.length || this.gameResult || !this.isGameing || this.userColor != 1) return
            let moves = this.boardInitMoves
            this._board.loadSgf(moves)
            this.genmove()
        },
        async captureResultSet(){
            // let color = null
            // let result = null
            // if(this.captureRR) result = this._common.getDrawResultKey()
            // else{
            //     if(this.blackWinCapture) result = 'B+R', color = 1
            //     else if(this.whiteWinCapture) result = 'W+R', color = -1
            // }
            // if(!color && !result) return
            // if(!result) result = 'N+R'
            // if(!this.captureRR){
            //     if(color != this.userColor) return
            // }else{
            //     if(this.isUserPlay) return
            // }
            // await this.gameResultSet(result)
            let color = this.captureWinColor
            if(!this.captureRR){
                if(color != this.userColor) return
            }else{
                if(this.isUserPlay) return
            }
            return this.captureResultSetAction()
        },
        async captureResultSetAction(){
            let color = this.captureWinColor
            let result = null
            if(this.captureRR) result = this._common.getDrawResultKey()
            else{
                if(this.blackWinCapture) result = 'B+R'
                else if(this.whiteWinCapture) result = 'W+R'
            }
            if(!color && !result) return
            if(!result) result = 'N+R'
            await this.gameResultSet(result)
        },
        async captureGameResultCheck(){
            if(!this.isCapture || !this.captureGameOver) return
            await this.captureResultSet()
        },
        async gameStateSyncAction(data){
            await this.timeUpdateByGameState(data)
            let color = this.colorNow == 1 ? 'black' : 'white'
            let mainTime = data[`${color}RemainTime`]
            let countdownTime = data[`${color}RemainCountdownTime`]
            let countdownNum = data[`${color}RemainCountdownNum`]
            if(mainTime <= 0 && countdownNum < data.countdownNum){
                let type = `countdown${countdownNum}`
                this.audioPlay(type)
            }
            this.timeStartCon()
        },
        async timeoutStepCallback(color){
            let fun = null
            if(color == 'black') fun = this.blackTimeoutSetp
            else if(color == 'white') fun = this.whiteTimeoutSetp
            return fun


            // let {type, countdownTime, countdownNum, color, mainTime} = opt || {}
            // if(mainTime !== undefined) this[`${color}RemainTime`].mainTime = mainTime
            // if(countdownTime !== undefined) this[`${color}RemainTime`].countdownTime = countdownTime
            // if(countdownNum !== undefined) this[`${color}RemainTime`].countdownNum = countdownNum


            // await this._common.nextTick.call(this)
            // if(!this.timeCheck(color)) return false
            // if(this.pauseTimeCheck(color)){
            //     this.gameTimeSync()
            //     return true
            // }
        },
        blackTimeoutSetp(val){
            let {type} = val ||{}
            this.audioTimeCheck({...val, type, color: 1})
            return this.timeoutStepNormal('black', type)
        },
        whiteTimeoutSetp(val){
            let {type} = val ||{}
            this.audioTimeCheck({...val, type, color: -1})
            return this.timeoutStepNormal('white', type)
        },
        audioTimeCheck(val){
            let {color, type, value, countdownNum} = val
            if(type == 'step') this.countdownStepHandl(value)
            if(color != this.userColor) return
            let voice = null
            if(type == 'mainTime') voice = 'count down start'
            if(type == 'countdownTime'){
                let c = color == 1 ? 'black' : color == -1 ? 'white' : null
                let timeData = this[`${c}RemainTime`]
                let data = this.gameState
                if(timeData){
                    let {mainTime} = timeData
                    if(mainTime <= 0 && countdownNum < data.countdownNum && countdownNum >= 1){
                        voice =  `countdown${countdownNum}`  
                    }   
                }
            }
            this.audioPlay(voice)
        },
        countdownStepHandl(value){
            let voice = `countdownSecond${value}`
            this.audioPlay(voice)
            this.timeRead(value)
        },
        timeRead(num){
            let {position, step, tryPlaySelected} = this
            if(tryPlaySelected || position != step) return false
            this._board.timeRead(num)
        },
        timeReadReset(){
            this._board.timeReadReset()
        },
        async timeoutStepNormal(color, type){
            if(type == 'step') return
            await this._common.nextTick.call(this)
            if(!this.timeCheck(color)) return false
            if(this.pauseTimeCheck(color)){
                this.gameTimeSync()
                return true
            }
        },
        timeCheck(color){
            let timeData = this[`${color}RemainTime`] || {}
            let {mainTime, countdownTime, countdownNum} = timeData
            if(mainTime > 0 || countdownTime > 0 || countdownNum > 1) return true
            return false
            // let c = color == 'black' ? 1 : -1
            // if(c != this.userColor) return
            // this.timeoutSet(color)
            // return false
        },
        pauseTimeCheck(color){
            if(this.isWatcher) return false
            let c = color == 'black' ? 1 : -1
            if(c == this.userColor) return true
            return false
        },
        
        gameTimeSync(opt){
            opt = opt || {}
            if(this.isWatcher) return
            let color = this.userColor == 1 ? 'black' : 'white'
            let timeData = this[`${color}RemainTime`]
            let params = this._common.deepCopy(this.genmoveParams)
            for(let i in opt){
                if(params[i] !== undefined) params[i] = opt[i]
            }
            this.api.gameTimeSync({params, noLoad: true, noError: true})
        },
        async playAgain(){
            if(this.resultFunLoading) return
            // this.LOADING.open()
            this.resultFunLoading = 'playAgain'
            let state = await this.playAgainCheck()
            this.resultFunLoading = null
            // this.LOADING.close()
            if(!state) return
            let config = this.playAgainConfig

            let res = await this.api.sendPlayAgain(config)
            if(res) this.playAgainSet()
        },
        async playAgainCheck(){
            await this.gameroomInfoInit()
            return this.playAgainCheckAction()
        },
        async playAgainCheckAction(){
            let {gameroomId: gameroom_id} = this
            let {blackUserCode, whiteUserCode} = this
            let usercode = this.usercode == blackUserCode ? blackUserCode : this.usercode == whiteUserCode ? whiteUserCode : null
            let res = await this.api.gamePlayer({gameroom_id})
            if(!res) return false
            let userInfo = {}
            for(let i in res){
                if(res[i].userCode != usercode){
                    userInfo = res[i]
                    break
                }
            }
            let {appUserStatus, webUserStatus, userStatus} = userInfo
            appUserStatus = Number(appUserStatus)
            webUserStatus = Number(webUserStatus)
            if(userStatus >= this.ENUMS.ROOM_PLAYER_LOGGED_OUT){
                this.$toast({
                    message: this._errorCode.game['user reject game rule']
                })
                return false
            }
            return this.rankGamePlayAgainCheck()
        },
        async rankGamePlayAgainCheck(){
            if(!this.isRankGame) return true
            if(!this.isSameLevel && !this.isAdjLevel){
                this.$toast({
                    message: this._errorCode.game['play again disabled']
                })
                return false
            }
            return true

        },
        getPlayAgainLevel(opt){
            let {blackUserCode, whiteUserCode} = opt || {}
            let {playerListObj} = this
            let {level: blackLevel = 0} = playerListObj[blackUserCode] || {}, {level: whiteLevel = 0} = playerListObj[whiteUserCode] || {}
            return {blackLevel, whiteLevel}
        },
        async quit(){
            if(!this.isWatcher){
                let st = await this.quitNotice()
                if(!st) return false
                await this.endGame()
            }
            
            this.setStatusIdle()
            // if(!this.pageStatus){
            //     let pageStatus = 'result'
            //     this.$emit('takeFun', {
            //         fun: 'refreshPage',
            //         data: {
            //             path: 'gamePlayFull',
            //             items: {
            //                 pageStatus
            //             }
            //         }
            //     })
            //     await this._common.nextTick.call(this)
            // }
            this.quitRoom()
        },
        async endGame(result){
            if(!this.isPlaying) return true
            let {gameId: game_id} = this
            let color = this.userColor == 1 ? 'W' : 'B'
            let game_result = `${color}+R`
            game_result = result || game_result
            let res = await this.api.endGame({game_id, game_result})
            return res
        },
        async quitNotice(){
            if(!this.isPlaying) return true
            let {quitMessage: message} = this
            let res = await this.messageNotice({
                message,
                buttons: [
                    {title: '确定', label: 0},
                    {title: '取消', label: 1, type: 'cancel'},
                ]
            })
            res = res || {}
            return res.label == 0
        },
        tryPlayCon(){
            this.tryPlay ? this.tryPlayOff() : this.tryPlayOn()
        },
        tryPlayOn(){
            this.tryPlay = true
            if(this.position == this.step) this.tryPlayData.newest = true
            else this.tryPlayData.newest = false
            this._board.tryPlayOpen()
        },
        async tryPlayOff(){
            this.tryPlay = false
            let {newest} = this.tryPlayData
            this._board.tryPlayClose()
            await this._common.nextTick.call(this)
            if(newest) this.changePos(this.step)
        },
        jumpTo(p){
            if(!this.gameResult && !this.isWatcher) return
            this._board.jumpTo(p)
        },
        changePos(e){
            if(!this.gameResult && !this.isWatcher) return
            if(isNaN(e)) e = this._board.board.position
            this._board.jumpToByposRange(e)
        },
        clearAllProbs(type){
            // if(this.areaSelected){
                
            //     if(type != 'board' || this.isWatcher || this.gameResult) this.areaOff()
            // } 
            // if(this.optionsSelected) this.propOff()
            // if(this.branchSelected) this.branchOff()
            // if(this.endGameJudgeSelected) this.endGameJudgeOff()
            if(this.gameJudgeSelected) this.gameJudgeOff()
        },
        playAgainStart(){
            this.blackReadTime = false
            this.whiteReadTime = false
        },
        actionNumNotice(type){
            let list = {
                undo: {
                    name: '悔棋',
                    message: this._errorCode.game[`reject use up`],

                },
                draw: {
                    name: '申请和棋',
                    message: this._errorCode.game[`reject use up`]
                },
                area: {
                    name: '领地',
                    message: this._errorCode.game[`probs use up`]
                }
                
            }
            let data = list[type]
            if(!data) return
            let num = this[`${type}RejectNum`]
            let message = data.message
            let name = data.name
            message = this._common.urlParse(message, {num, name})
            if(!message) return
            this._common.confirmPro.open({
                message,
                confirmType: 'c3',
                buttons: [
                    {title: '确定', type: 'confirm'}
                ]
            })
        },
        async actionConfirm(message){
            let res = await this._common.confirmPro.open({
                message,
                confirmType: 'c3',
                buttons: [
                    {title: '确认', label: 0},
                    {title: '取消', label: 1, type: 'cancel'},
                ]
            })
            res = res || {}
            return res.label == 0
        },
        async undoControl(){
            if(this.undoDisabled) return
            if(this.remainBackMoveRejectNum <= 0) return this.actionNumNotice('undo')
            let res = await this.actionConfirm(this._errorCode.game['undo confirm'])
            if(!res) return
            let action_type = this.ENUMS.ACTION_UNDO
            let {gameId: game_id} = this
            res = await this.api.sendConfirmAction({game_id, action_type})
        },
        
        timePause(){
            let list = ['blackTime', 'whiteTime']
            for(let i in list){
                let key = list[i]
                let com = this.$refs[key]
                if(!com || !com.timePause) continue
                com.timePause()
            }
        },
        closeAllProbs(){
            if(this.isWatcher) return
            // if(this.areaSelected) this.areaOff()
            // if(this.branchSelected) this.branchOff()
            // if(this.optionsSelected) this.propOff()
            if(this.gameJudgeSelected) this.gameJudgeOff()
        },
        async applyJudgeAction(){
            this.applyJudgeInit()
            let res = await this.engineApplyArea()
            return this.applyJudgeGameSend(res)
        },
        async applyJudgeGameSend(data){
            let {winrate, delta, area} = data || {}
            let {gameId: game_id} = this
            let res = await this.api.applyJudge({winrate, delta, area, game_id})
        },
        applyJudgeInit(){
            this.applyJudge = {
                active: false,
                winrate: null,
                delta: null,
                result: null,
                sender: null,
            }
            let data = this.applyJudgeUserState
            for(let i in data){
                this.$delete(this.applyJudgeUserState, i)
            }
        },
        async engineApplyArea(opt){
            opt = opt || {}
            let moves = this._board.getSituation()
            let params = this.probParamsGet(moves)
            params = {...params, ...opt}
            let res = await this.api.applyArea(params)
            res = res || {}
            if(res.code != 0) return false
            return res.data
        },
        probParamsGet(moves){
            if(!moves){
                moves = this.getSituation()
            }
            let opt = {
                moves: moves,
                boardsize: this.boardSize,
                komi: this.komi,
            }
            return opt
        },
        areaHandledcnn(res, color){
            let data = res.area
            this._board.areaOn(data, color)
            this.area.data = this._common.deepCopy(res)
            this.area.color = color
        },
        async applyJudgeResultReceive(res, type){
            let {sender} = res
            this.$set(this.applyJudgeUserState, sender, type)
            let st = await this.applyJudgeResultStateCheck()
            if(!st) return
            this.applyJudgeResultSet()
        },
        async applyJudgeResultStateCheck(){
            let {applyJudgeUserState: userState, playerList, usercode} = this
            let duration = 3000, className = 'invite-toast-box'
            if(userState[usercode] !== undefined && !userState[usercode]){
                this.$toast({
                    message: this._errorCode.game['apply judge reject own'],
                    duration,
                    className,
                });
                return false
            }
            let f = true
            for(let i in userState){
                if(!userState[i]){
                    f = false
                    break
                }
            }
            if(!f || Object.keys(userState).length == playerList.length) this.applyJudgeOff()
            if(!f){
                this.$toast({
                    message: this._errorCode.game['apply judge reject'],
                    duration,
                    className,
                });
            }
            if(Object.keys(userState).length != playerList.length) return false
            return f
        },
        applyJudgeResultSet(){
            let {result, sender} = this.applyJudge
            if(sender != this.usercode) return
            let data = this._common.deepCopy(this.applyJudge.orignalData)
            this.gameResultSet(result)
            this.applyJudgeOff()
        },
        async applyDrawAction(type){
            type = type == 'accept' ? true : false
            if(type){
                let result = this._common.getDrawResultKey()
                await this.gameResultSet(result)
            }
            this.actionGameStateUpdate(type, 'draw')
        },
        async actionGameStateUpdate(state, type){
            if(this.isWatcher) return
            if(this.gameResult) return
            let list = {
                'undo': {
                    defaultData: 'backMoveRejectNum',
                    remainData: 'RemainBackMoveRejectNum'
                },
                'draw': {
                    defaultData: 'drawRejectNum',
                    remainData: 'RemainDrawRejectNum'
                },
                'area': {
                    defaultData: 'areaUsageNum',
                    remainData: 'RemainAreaUsageNum'
                },
            }
            let data = list[type]
            if(!data) return
            let color = this.userColor == 1 ? 'black' : 'white'
            let index = `${color}${data.remainData}`
            let params = this._common.deepCopy(this.genmoveParams)
            // if(!state) params[index]--
            // else params[index] = params[data.defaultData]
            params[index]--
            this.gameStateSet(params)
        },
        async gameStateSet(params){
            params = params || {}
            params = {...params, noLoad: true}
            let res = await this.api.gameStateSet(params)
            if(res) this.$emit('takeFun', {
                fun: 'gameStateSet',
                data: params
            })
        },

        timeContinue(){
            if(this.gameResult) return
            let color = this.colorNow == 1 ? 'black' : 'white'
            let timeData = this[`${color}RemainTime`] || {}
            let com = this.$refs[`${color}Time`]
            if(!com) return
            let {mainTime, countdownTime, countdownNum} = timeData
            mainTime = mainTime || 0
            countdownTime = countdownTime || 0
            countdownNum = countdownNum || 0
            if(!this.timeCheck(color)){
                return
            }
            let type = 'mainTime'
            if(mainTime <= 0){
                type = 'countdownTime'
                if(countdownNum <= 1 && countdownTime <= 0){
                    return
                }
            }
            com.timeContinue(type)
        },
        async undoAction(type){
            type = type == 'accept' ? true : false
            if(type){
                this._board.back(2)
                await this._common.nextTick.call(this)
                let params = this.genmoveParams
                let res = await this.api.backMove(params)
            }

            this.actionGameStateUpdate(type, 'undo')
        },
        async setResult_draw(){
            if(this.remainDrawRejectNum <= 0) return this.actionNumNotice('draw')
            if(this.applyRRDisabled) return
            let res = await this.actionConfirm(this._errorCode.game['apply draw confirm'])
            if(!res) return
            let action_type = this.ENUMS.ACTION_DRAW
            let {gameId: game_id} = this
            res = await this.api.sendConfirmAction({game_id, action_type})
        },
        async passControl(){
            if(!this.playCheck('pass')) return
            let message = this.passNotice()
            let res = await this._board.passBySocialGame({doubleCheck: false, message})
            if(!res) return
            this.funAfterAction('pass')
        },
        playCheck(type){
            this.tryPlayCheck()
            if(this.passDisabled) return false
            type = type || 'play'
            if(type != 'pass' && this.chatOffForPlay()) return false
            return true
        },
        tryPlayCheck(){
            if(this.tryPlaySelected) return
            if(!this.isWatcher && !this.gameResult) return
            this.tryPlayOn() 
        },
        chatOffForPlay(){
            let f = false
            // if(this.areaSelected){
            //     this.areaOff()
            //     f = true
            // }
            // if(this.optionsSelected){
            //     f = true
            //     this.propOff()
            // }
            if(this.gameJudgeSelected){
                f = true
                this.gameJudgeOff()
            }
            // if(this.branch.active){
            //     f = true
            //     this.branchOff()
            // }
            return f
        },
        passNotice(){
            let {situation} = this
            let num = this._common.getGameJudgePos(this.boardSize)
            if((Number(situation.length) + 1) < num){
                return
            }
            let data = situation[situation.length - 1]
            if(!data || data != -1){
                return
            }
            let message = this._errorCode.game['double pass confirm']
            return message
        },
        boardChartCheck(type){
            let fun = this.boardChatFun[type]
            if(!fun) return
            for(var i in fun.off){
                if(this[fun.off[i] + 'Off']){
                    this[fun.off[i] + 'Off']()
                }
            }
            for(i in fun.clear){
                if(this[fun.clear[i] + 'Clear']){
                    this[fun.clear[i] + 'Clear']()
                }
            }
        },
        computerPlayResponseGet(){
            return new Promise(res =>{
                if(!this.genmoveLoading) res()
                this.computerPlayResponse = res
            })
        },
        genmoveTimeParse(data){
            let {mainTime, countdownTime, countdownNum} = data
            let {blackReadTime, blackRemainCountdownTime, blackRemainCountdownNum} = data
            let {whiteReadTime, whiteRemainCountdownTime, whiteRemainCountdownNum} = data
            if(blackReadTime === undefined || blackReadTime === null) blackReadTime = mainTime
            if(blackRemainCountdownTime === undefined || blackRemainCountdownTime === null) blackRemainCountdownTime = countdownTime
            if(blackRemainCountdownNum === undefined || blackRemainCountdownNum === null) blackRemainCountdownNum = countdownNum

            if(whiteReadTime === undefined || whiteReadTime === null) whiteReadTime = mainTime
            if(whiteRemainCountdownTime === undefined || whiteRemainCountdownTime === null) whiteRemainCountdownTime = countdownTime
            if(whiteRemainCountdownNum === undefined || whiteRemainCountdownNum === null) whiteRemainCountdownNum = countdownNum
            return {
                ...data,
                blackReadTime, blackRemainCountdownTime, blackRemainCountdownNum,
                whiteReadTime, whiteRemainCountdownTime, whiteRemainCountdownNum
            }
        },
        async gameEndVoice(){
            await this._common.nextTick.call(this)
            this.$refs.gameResult.gameEndVoice()
        },
        async resultWatchStart(t){
            t = t || 0
            let delayTime = 5000
            if(this.gameResult || this.gameIsEnd) return
            if(t >= this.PARAMS.capture_game_result_wait_time) return this.gameResultSetByError()
            await this._common.settimeout(delayTime)
            await this.gameroomInfoInitAction({noLoad: true})
            t += delayTime
            return this.resultWatchStart(t)
        },
        async gameResultSetByError(){
            this.captureResultSetAction()
        },
        gameReadyIntervalCheck(t){
            let {blackConnectionStatus, whiteConnectionStatus} = this
            blackConnectionStatus = Number(blackConnectionStatus)
            whiteConnectionStatus = Number(whiteConnectionStatus)
            let maxTime = this.PARAMS.game_ready_interval_max_time
            let f = null
            if(!blackConnectionStatus || !whiteConnectionStatus) f = 1
            if(t >= maxTime) f = 2
            if(f) return this.gameroomCloseByReadyError(f)
            return true
        },
        async gameroomCloseByReadyError(f){
            // let result = this.this.PARAMS.social_game_none_result
            // let {gameId: game_id} = this
            // let game_result = this.gameResultRepair(result)
            // let res = await this.api.endGame({game_id, game_result})
            // await this.gameroomCloseByReadyErrorNotice(f)
            // this.returnToGamezone()
            // return false

            // let message = this._errorCode.game[`game ready check status ${f}`]
            // if(message){
            //     let duration = 5000
            //     this.$toast({
            //         message,
            //         duration
            //     })
            // }
            return true
        },
        async gameroomCloseByReadyErrorNotice(){
            let message = this._errorCode.game['']
        },
    },
    created() {},
    components: {},
    computed: {
        captureWinColor(){
            let color = null
            if(this.blackWinCapture) color = 1
            else if(this.whiteWinCapture) color = -1
            return color
        },
        
        passDisabled(){
            if(this.gameResult) return true
            if(this.userPlayDisabled) return true
            return false
        },
        komi(){
            let meta = this.gameMeta || {}
            let {komi} = meta
            return komi
        },
        undoRejectNum(){
            return this.gameState.backMoveRejectNum || 0
        },
        drawRejectNum(){
            return this.gameState.drawRejectNum || 0
        },
        areaRejectNum(){
            return this.gameState.areaUsageNum || 0
        },
        undoDisabled(){
            if(this.gameResult) return true
            if(this.realPosition < 2) return true
            if(this.playDisabled) return true
            if(this.remainBackMoveRejectNum <= 0) return true
            let key = this.userColor == 1 ? 'black' : 'white'
            let num = this.gameState[`${key}RemainBackMoveRejectNum`] || 0
            if(num <= 0) return true
            return false
        },
        drawDisabled(){
            if(this.remainDrawRejectNum <= 0) return true
            if(this.gameResult) return true
            return !this.isUserPlay
        },
        remainDrawRejectNum(){
            let key = this.userColor == 1 ? 'black' : 'white'
            return this.gameState[`${key}RemainDrawRejectNum`]
        },
        playDisabled(){
            // if(this.areaLoading || this.branchLoading || this.optionsLoading) return true
            if(!this.nonePlayWatch) return true
            if(this.tryPlaySelected) return false
            if(this.gameResult) {
                if(!this.tryPlay) return true
                else return false
            }
            if(!this.isUserPlay) return true
            if(this.applyJudgeSelected) return true
            return false
        },
        applyJudgeSelected(){
            return this.applyJudge.active
        },
        remainBackMoveRejectNum(){
            let key = this.userColor == 1 ? 'black' : 'white'
            return this.gameState[`${key}RemainBackMoveRejectNum`]
        },
        quitMessage(){
            let message = '确定要退出吗？'
            let p = this.step
            let reP = this.PARAMS.playResignPosition
            if(this.isPlaying && p >= reP) message += '退出将会自动认输'
            return message
        },
        playAgainConfig(){
            let meta = this.gameMeta || {}
            let {blackUserCode, whiteUserCode} = this.playAgainColor
            let {inviterUserCode, inviteeUserCode} = this.gameroomInfoCul
            let guess = this.playAgain_guess
            let currentPlayer = this.usercode == inviterUserCode ? inviteeUserCode : inviterUserCode
            let {gameroomId} = this
            let {rule} = this
            let {boardSize, handicap, mainTime, countdownTime, countdownNum, gamename, gameType, gameRound = 1} = meta
            let {blackLevel, whiteLevel} = this.getPlayAgainLevel({blackUserCode, whiteUserCode})
            let komi = this.playAgainKomi
            gameRound++
            let items = {
                boardSize, handicap, komi, mainTime, countdownTime, countdownNum, 
                blackUserCode, whiteUserCode, blackLevel, whiteLevel, gamename, 
                gameType, gameroomId, inviterUserCode, inviteeUserCode,
                currentPlayer, guess, gameRound, startMoveNum: this.startMoveNum,
                rule
            }
            return items
        },
        playAgainKomi(){
            return this.isRankGame ? this.playAgainKomi_rank : this.playAgainKomi_normal
        },
        playAgainKomi_normal(){
            let meta = this.gameMeta || {}
            let {komi} = meta
            return komi
        },
        playAgainKomi_rank(){
            // return this.rankGameColor == 0 ? this.PARAMS.default_komi : 0
            let meta = this.gameMeta || {}
            let {komi} = meta
            return komi
        },
        playAgainColor(){
            return this.isRankGame ? this.playAgainColor_rank : this.playAgainColor_normal
        },
        playAgainColor_rank(){
            let {guess, blackUserCode, whiteUserCode} = this.gameMeta || {}
            let color = this.rankGameColor
            if(color == 0){
                let tem = blackUserCode
                blackUserCode = whiteUserCode
                whiteUserCode = tem
            }else{
                let data = {blackUserCode, whiteUserCode}
                let key1 = color == 1 ? 'black' : 'white'
                let key2 = color == 1 ? 'white' : 'black'
                data[`${key1}UserCode`] = this.usercode
                data[`${key2}UserCode`] = this.usercode == blackUserCode ? whiteUserCode : blackUserCode
                blackUserCode = data.blackUserCode
                whiteUserCode = data.whiteUserCode
            }
            return {blackUserCode, whiteUserCode}
        },
        playAgainColor_normal(){
            let {guess, blackUserCode, whiteUserCode} = this.gameMeta || {}
            guess = Number(guess)
            if(guess){
                let tem = blackUserCode
                blackUserCode = whiteUserCode
                whiteUserCode = tem
            }
            return {blackUserCode, whiteUserCode}
        },
        playAgain_guess(){
            let {guess} = this.gameMeta
            if(this.isRankGame){
                let {rankGameColor: color} = this
                guess = color == 0 ? 1 : 0
            }
            return guess
        },
        rankGameColor(){
            // let {
            //     blackUserInfo: {level: blackLevel, userCode: blackUserCode}, 
            //     whiteUserInfo: {level: whiteLevel, userCode: whiteUserCode},
            // } = this
            // let data = {
            //     blackUserCode,
            //     whiteUserCode
            // }
            
            // let key = blackLevel > whiteLevel ? 'black' : 'white'
            // if(this.isAdjLevel){
            //     if(data[`${key}UserCode`] == this.usercode) return -1
            //     return 1
            // }
            return 0
        },
        isSameLevel(){
            let {
                blackUserInfo: {level: blackLevel}, 
                whiteUserInfo: {level: whiteLevel}
            } = this
            return blackLevel == whiteLevel
        },
        isAdjLevel(){
            let {
                blackUserInfo: {level: blackLevel}, 
                whiteUserInfo: {level: whiteLevel}
            } = this
            let list = this.PARAMS.user_level
            let blackLevelIndex, whiteLevelIndex
            for(let i in list){
                if(list[i].value == blackLevel) blackLevelIndex = i
                if(list[i].value == whiteLevel) whiteLevelIndex = i
            }
            let f = Math.abs(blackLevelIndex - whiteLevelIndex) <= this.PARAMS.rank_max_level
            return f
        },
        isRankGame(){
            let {gameType} = this
            return gameType == this.ENUMS.EXERCISE_CAPTURE_TASTE_COMPETITIVE_MATCH || gameType == this.ENUMS.EXERCISE_CONTAIN_TASTE_COMPETITIVE_MATCH
        },
        readTimeStartDisabled(){
            let {gameState: {mainTime}, isWatcher, colorNow, userColor} = this
            if(isWatcher) return true
            if(mainTime == 0 && mainTime !== undefined && colorNow == 1) return true
            if(colorNow != userColor) return true
            return false
        },
        needSyncTimeByNoneMainTime(){
            let {handicap, gameState: {mainTime}, userColor, colorNow, situation} = this
            if(mainTime > 0){
                return false
            }
            if(userColor != colorNow){
                return false
            }
            let num = situation.length
            if(handicap > 0) num = situation.length - (handicap * 2 - 1)
            if(num != 0 && num != 1){
                return false
            }
            return true
        },
        timeDisabled(){
            if(this.gameResult) return true
            return false
        },
        // gameOver(){
        //     if(this.isCapture) return this.captureGameOver
        //     if(this.isPlay) return this.playGameOver
        //     return false
        // },
        // playGameOver(){
        //     let {judge: {data: {winner = 'U'} = {}} = {}} = this
        //     let f = userResign ||
        //     AIResign ||
        //     userTimeout ||
        //     AITimeout ||
        //     winner != 'U'
        //     return f
        // },
        colorNow(){
            let {stepNowCul: step} = this
            return step % 2 == 0 ? 1 : -1
        },
        captureRR(){
            let {positionCul, blackRemove, whiteRemove, captureNum, moveNum} = this
            return positionCul >= moveNum && blackRemove < captureNum && whiteRemove < captureNum
        },
        captureGameOver(){
            // if(!this.gameroomInfo) return false
            if(!this.isCapture) return false
            let {positionCul, blackRemove, whiteRemove, captureNum, moveNum} = this
            return positionCul >= moveNum || 
            blackRemove >= captureNum || 
            whiteRemove >= captureNum
        },
        // blackWin(){
        //     if(this.isCapture) return this.blackWinCapture
        //     if(this.isPlay) return this.blackWinPlay
        // },
        blackWinCapture(){
            let {blackRemove, captureNum} = this
            if(blackRemove >= captureNum) return true
            return false
        },
        // blackWinPlay(){},
        // whiteWin(){
        //     if(this.isCapture) return this.whiteWinCapture
        //     if(this.isPlay) return this.whiteWinPlay
        // },
        whiteWinCapture(){
            let {whiteRemove, captureNum} = this
            if(whiteRemove >= captureNum) return true
            return false
        },
        // whiteWinPlay(){},

        tryPlaySelected(){
            return this.tryPlay
        },
        realPosition(){
            let {position, boardInitMoves} = this
            let p = position - boardInitMoves.length
            if(p < 0) p = 0
            return p
        },
        positionNowCul(){
            if(!this.tryPlaySelected) return this.position
            let data = this.boardTryPlayData || {}
            return data.position || this.position
        },
        stepNowCul(){
            if(!this.tryPlaySelected) return this.step
            let data = this.boardTryPlayData || {}
            return data.step ? data.step.length - 1 : this.step
        },
        genmoveParams(){
            let {gameState = {}} = this
            let params = {
                wsGameId: this.gameId,

                blackUserCode: this.blackUserCode,
                whiteUserCode: this.whiteUserCode,

                situation: this.situationLocal,
                gameStatus: gameState.gameStatus,
                currentPlayer: this.currentPlayer,

                mainTime: this.mainTime,
                blackRemainTime: this.blackRemainTime.mainTime,
                whiteRemainTime: this.whiteRemainTime.mainTime,

                countdownTime: this.countdownTime,
                blackRemainCountdownTime: this.blackRemainTime.countdownTime,
                whiteRemainCountdownTime: this.whiteRemainTime.countdownTime,

                countdownNum: this.countdownNum,
                blackRemainCountdownNum: this.blackRemainTime.countdownNum,
                whiteRemainCountdownNum: this.whiteRemainTime.countdownNum,

                backMoveRejectNum: gameState.backMoveRejectNum,
                blackRemainBackMoveRejectNum: gameState.blackRemainBackMoveRejectNum,
                whiteRemainBackMoveRejectNum: gameState.whiteRemainBackMoveRejectNum,

                drawRejectNum: gameState.drawRejectNum,
                blackRemainDrawRejectNum: gameState.blackRemainDrawRejectNum,
                whiteRemainDrawRejectNum: gameState.whiteRemainDrawRejectNum,

                areaUsageNum: gameState.areaUsageNum,
                blackRemainAreaUsageNum: gameState.blackRemainAreaUsageNum,
                whiteRemainAreaUsageNum: gameState.whiteRemainAreaUsageNum,

                offlineTime: this.offlineTime,
                blackRemainOfflineTime: this.blackRemainOfflineTime,
                whiteRemainOfflineTime: this.whiteRemainOfflineTime,

                startMoveNum: this.startMoveNum

                
            }
            params = this.genmoveTimeParse(params)

            return params
        },
        blackRemainOfflineTime(){
            let {gameState: {blackRemainOfflineTime} = {}} = this.gameMeta
            return blackRemainOfflineTime
        },
        whiteRemainOfflineTime(){
            let {gameState: {whiteRemainOfflineTime} = {}} = this.gameMeta
            return whiteRemainOfflineTime
        },
        offlineTime(){
            let {gameState: {offlineTime} = {}} = this.gameMeta
            return offlineTime
        },
        currentPlayer(){
            let {gameMeta: {blackUserCode, whiteUserCode}, now} = this
            if(now == 1) return blackUserCode
            return whiteUserCode
        },
        situationLocal(){
            let {position} = this
            let moves = this.getSituation(position)
            return moves
        },
        confirmDisabled(){
            if(this.gameResult) return true
            let {gamePlayCoor} = this
            return !gamePlayCoor || !Object.keys(gamePlayCoor).length
        },
        gameroomStatus(){
            let {gameroomStatus = 0} = this.gameroomInfo || {}
            return gameroomStatus
        },
        gamePlaying(){
            if(this.gameResult){
                return false
            }
            let {gameroomStatus = 0} = this
            return gameroomStatus >= this.ENUMS.ROOM_PLAYING && gameroomStatus < this.ENUMS.ROOM_ENDED
        },
        boardParamsWatch(){
            let {boardState, handicap} = this
            if(!boardState || !handicap) return false
            return true
        },
        handicap(){
            let info = this.gameInfo
            let {handicap} = info
            return handicap || 0
        },
        blackDisconnect(){
            let {disconnectUser: list, blackUserCode: userCode} = this
            list = this._common.deepCopy(list)
            if(!list[userCode]) return null
            let {blackRemainOfflineTime} = this.gameState
            list[userCode].remainTime = blackRemainOfflineTime
            return list[userCode]
        },
        whiteDisconnect(){
            let {disconnectUser: list, whiteUserCode: userCode} = this
            list = this._common.deepCopy(list)
            if(!list[userCode]) return null
            let {whiteRemainOfflineTime} = this.gameState
            list[userCode].remainTime = whiteRemainOfflineTime
            return list[userCode]
        },
        disconnectWatch(){
            let {blackDisconnect, whiteDisconnect} = this
            if(blackDisconnect) return 1
            if(whiteDisconnect) return -1
            return false
        },
        timeReadResetWatch(){
            let {gameResult, position, step, tryPlaySelected} = this
            return {gameResult, position, step, tryPlaySelected}
        },
        setGameResultWatch(){
            let {pageHasInit, situation, gamePlaying, gameResult} = this
            return {pageHasInit, situation, gamePlaying}
        },
        gameReadyWatch(){
            if(!this.gameroomInfo) return false
            let {gameroomStatus = 0} = this
            if(this.gameResult || this.isCreatedStatus || gameroomStatus >= this.ENUMS.ROOM_ENDED || this.gamePlaying || this.pageDestroy) return false
            return true
        }
    },
    watch: {
        gameReadyWatch: {
            handler(val){
                this.gameReadyIntervalCon(val)
                if(!val && this.$refs.gameLoading){
                    this.$refs.gameLoading.close()
                }
            },
            deep: true,
            immediate: true
        },
        timeReadResetWatch: {
            handler(val, oldVal){
                if(this._common.isSameObject(val, oldVal)) return
                let {gameResult, position, step, tryPlaySelected} = val
                if(gameResult || tryPlaySelected || position != step) return this.timeReadReset()
            },
            deep: true,
            immediate: true
        },
        disconnectWatch: {
            handler(val, oldVal){
                if(val == oldVal) return
                if(!val) return this.timeStartCon('continue')

                if(this.colorNow == val) this.timeStop()

            },
            deep: true,
            immediate: true
        },
        captureGameOver: {
            async handler(val, oldVal){
                if(this.gameResult || !val || !this.isGameing) return
                if(!this.gamePlaying) return
                this.timeStop()
                this.$refs.gameLoading.open({
                    message: this._errorCode.game['capture game over']
                })
                this.gameroomInfoTimeoutEnd()
                await this.resultWatchStart()
                this.gameroomInfoTimeoutStart()
                
            },
            deep: true,
            immediate: true
        },
        setGameResultWatch: {
            async handler(val, oldVal){
            if(this._common.isSameObject(val, oldVal) || !this.gamePlaying) return
                await this._common.nextTick.call(this)
                this.captureGameResultCheck()
            },
            deep: true,
            immediate: true
        },
        situation: {
            async handler(val, oldVal){
                if(this._common.isSameObject(val, oldVal)) return
                let state = await this.boardHand()
                if(!state) this.closeAllProbs()
                await this._common.nextTick.call(this)
                this.captureGameResultCheck()
            },
            deep: true,
            immediate: true
        },
        gameId: {
            handler(val, oldVal){
                if(!val || val == oldVal) return
                this.gameSubscribe()
            },
            deep: true,
            immediate: true
        },
        stepNowCul: {
            handler(){
                if(!this.gamePlaying) return
                if(!this.isReady && !this.isWatcher) return
                this.timeStartCon()
            },
            deep: true,
            immediate: true
        },
        boardParamsWatch: {
            handler(val){
                if(!val) return
                this.handicapInit()
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {},
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
    