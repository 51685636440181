<!--  -->
<template>
    <div class='game-com-action-confirm'>
        <div class="action-waiting flex-center" v-if="isWaiting">
            <div class="action-waiting-body flex-center">
                <span class="texts-large-b">{{waitingText}}</span>
                <span class="text-normal">（{{countdownVal}}s）</span>
            </div>
        </div>
        <div class="action-confirm" v-if="isConfirm">
            <confirmPro ref="leConfirm" class="action-confirm-play-confirm">
                <div class="undo-confirm-box" slot="message">
                    <span class="text-normal bold">{{confirmText}}</span>
                    <!-- <span class="text-normal">（{{countdownVal}}s）</span> -->
                </div>
                <div class="foot-box flex-center" slot="foot">
                    <sl-button :class="btnCssCul(v)" v-for="(v, i) in buttons" :key="i" @click="funHandle(v)" :type="v.type || 'confirm'">
                        <span class="text-normal bold">{{v.name}}</span>
                        <span class="text-normal bold" v-if="v.countdown">({{countdownVal}}s)</span>
                    </sl-button>
                    <!-- <div
                    class="le-confirm-btn scale-button" :class="btnCssCul(v)" v-for="(v, i) in buttons" :key="i" @click="funHandle(v)">
                        <span class="text-normal">{{v.name}}</span>
                        <span class="text-normal" v-if="v.countdown">（{{countdownVal}}s）</span>
                    </div> -->
                </div>
            </confirmPro>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            
        },
        data() {
            return {
                waitingText: '',
                confirmText: '',
                sender: null,
                action: false,
                countdown: null,
                countdowner: null,
                status: null,
                usercode: this.$store.state.app.usercode,
                callback: null,
                actionType: null,
                buttons: []
            };
        },
        methods: {
            paramsInit(opt){
                this.countdowner = null
                this.status = null
                this.sender = null
                this.callback = null
                this.actionType = null
                this.buttons = []
                this.usercode = this.$store.state.app.usercode
                this._common.paramsInit.call(this, opt)
                this.countdown = this.countdownValCul * 1000
            },
            open(opt){
                this.paramsInit(opt)
                this.action = true
                return new Promise(success => {
                    this.callback = success
                    let fun = this.handFun 
                    if(!fun || !this[fun]) return
                    this[fun]()
                })
            },
            close(){
                this.countdownStop()
                if(this.$refs['leConfirm']) this.$refs['leConfirm'].close()
                this.action = false
                this.paramsInit()
            },
            async confirmHand(){
                await this._common.nextTick.call(this)
                this.countdownStart()
                this.buttons = [
                    {name: '同意', label: 0, fun: this.handTake, type: 'confirm'},
                    {name: '拒绝', label: 1, type: 'cancel', fun: this.handTake, countdown: true},
                ]
                this.$refs['leConfirm'].open({
                    confirmType: 'c3',
                    showClose: false
                })
                // let res = await this.$refs['leConfirm'].open({
                //     button: [
                //         {name: '同意', label: 0},
                //         {name: '拒绝', label: 1, type: 'cancel'},
                //     ]
                // })
                // res = res || {}
                // this.response(res.label == 0)
            },
            handTake(res){
                res = res || {}  
                this.response(res.label == 0)
            },
            async countdownStart(){
                await this._common.settimeout(1000)
                this.countdownStop()
                if(!this.action) return
                let time = this.countdown
                let speed = 1000
                this.countdowner = setInterval(() => {
                    if(this.countdown <= 0){
                        this.countdownStop()
                        return this.response(false)
                    }
                    this.countdown = this.countdown - speed
                }, speed);
            },
            async waitHand(){
                await this._common.nextTick.call(this)
                this.countdownStart()
            },
            countdownStop(){
                if(!this.countdowner) return
                clearInterval(this.countdowner)
            },
            response(res){
                if(!this.callback) return
                this.callback(res)
                this.close()
            },
            
            async setStatus(type, message){
                this.countdownStop()
                this.status = type
                return this.noticeCheck(message)
            },
            async noticeCheck(message){
                let {status} = this
                if(!status) return
                if(this.$refs['leConfirm']) this.$refs['leConfirm'].close()
                message = message || this.waitingText
                let duration = 3000, className = 'invite-toast-box'
                this.$toast({
                    message,
                    duration,
                    className,
                });
                
                this.close()
            },
            btnCssCul(val){
                if(!val.type || val.type == 'confirm') return 'confirm store-button-bcg'
                if(val.type == 'cancel') return 'cancel-login-register-button-bcg cancel'
                return val.type
            },
            funHandle(val){
                if(val.fun) val.fun(val)
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            closeBtnDisabled(){
                return this.status ? false : true
            },
            countdownVal(){
                let {countdown} = this
                return (countdown / 1000).toFixed(0)
            },
            handFun(){
                let fun = 'waitHand'
                if(this.isConfirm) fun = 'confirmHand'
                return fun
            },
            countdownValCul(){
                if(this.isWaiting) return this.PARAMS.actionCountdownWait
                return this.PARAMS.actionCountdown
            },
            isWaiting(){
                return this.sender == this.usercode && !this.status
            },
            isConfirm(){
                return this.sender != this.usercode && !this.status
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.countdownStop()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .game-com-action-confirm{
        color: #333;
        .action-waiting{
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.6);
            z-index: 301;
            .action-waiting-body{
                width: 440px;
                height: 115px;
                background: #DDDDDD;
                border-radius: 15px;
                color: #333;
                flex-wrap: wrap;
            }
            .close-box{
                width: 100%;
                display: flex;
                justify-content: center;
                .le-button{
                    margin: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    width: 154px;
                    height: 41px;
                }
            }
        }
        .action-confirm-play-confirm{
            padding: 0px;
        }
        .action-confirm{
            .undo-confirm-box{
                color: #6F5E65;
                padding: 25px;
                .gotu-confirm-pro{
                    padding: 0px;
                }
            }
            .foot-box{
                width: 100%;
                justify-content: space-around;
            }
        }
        .com-global-sl-button.cancel{
            width: 80px;
            white-space: nowrap;
        }
    }
</style>